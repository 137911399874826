import {
    formatTimestampFromSortKey,
    getItemName,
    getSpendFieldsSorted,
    ignoreChange,
    onDetailsClick,
} from '../AuditUtil'
import { capitalizeFirstLetter } from '../../../common/Util'
import { AuditState } from '../useAuditState'
import { Dispatch } from 'react'
import { ACTION_TYPES, AUDIT_LOG_GROUPS } from '../Constant'
import _ from 'lodash'
export interface FormatterParameters {
    items: any[]
    state: AuditState
    dispatch: Dispatch<any>
}
export const formatRevisionNameForColumnDisplay = (revisionMetadata, addDeletedSuffix?) => {
    const revisionNumber = revisionMetadata?.revision_number ?? ''
    const revisionTitle = revisionMetadata?.revision_title
        ? ` - ${revisionMetadata.revision_title}`
        : ''
    const isDeleted = revisionMetadata?.archived || addDeletedSuffix ? ' (deleted)' : ''
    return `Rev. ${revisionNumber}${revisionTitle}${isDeleted}`
}

const getRevisionNameFromState = (revisionId, planId, state) => {
    let selectedRevision = state.revisionsMap.data.get(revisionId)
    if (_.isEmpty(selectedRevision)) {
        const archivedRevisionMapForPlan = state.archivedRevisionsByPlan.data[planId] ?? new Map()
        selectedRevision = archivedRevisionMapForPlan.get(revisionId)
    }
    return formatRevisionNameForColumnDisplay(selectedRevision)
}

export const formatSpendAudits = (params: FormatterParameters) => {
    const { items, state, dispatch } = params
    const spendExpenditureFields = getSpendFieldsSorted()

    const formattedData: any[] = []
    const planId = state.selectPlanData.selected.value || ''
    if (!planId) {
        return
    }
    const programs = state.programsByPlan.data[planId]
    items.forEach((rawEntry) => {
        const rowProcessed = ignoreChange(rawEntry)
        if (rowProcessed.ignoreRow) {
            return
        }
        const entryProcessed = {
            ...rawEntry,
            differences: rowProcessed.differences,
            prev_state: rowProcessed.prev_state,
            next_state: rowProcessed.next_state,
        }
        const objectName = `${capitalizeFirstLetter(rawEntry.object_type)} ${getItemName(rawEntry)}`
        const actionObjectType = `${capitalizeFirstLetter(rawEntry.action)} ${capitalizeFirstLetter(rawEntry.object_type)}`
        formattedData.push({
            date: formatTimestampFromSortKey(rawEntry),
            program_id: rawEntry.program_id,
            program_name: programs ? programs.get(rawEntry.program_id)?.program_name : '',
            org_name: state.orgsMap.data.get(rawEntry.org_id)?.org_name,
            group_name: state.groupsMap.data.get(rawEntry.group_id)?.group_name,
            user_alias: rawEntry.user_alias,
            action: rawEntry.action,
            action_formatted: `${capitalizeFirstLetter(rawEntry.action)}`,
            action_object_type: actionObjectType,
            revision_name: getRevisionNameFromState(rawEntry.revision_id, rawEntry.plan_id, state),
            on_details_click: () =>
                onDetailsClick(
                    { ...entryProcessed, override_key_sort: spendExpenditureFields },
                    dispatch,
                ),
            differences: rowProcessed.differences,
            prev_state: rowProcessed.prev_state,
            next_state: rowProcessed.next_state,
            item_name: objectName,
            plan_id: rawEntry.plan_id,
            default_val: 0,
            override_display_keys: ['total_expenditure'],
            include_details: true,
            row_id: `${rawEntry.business_entity_id}_${rawEntry.timestamp_sort}`,
        })
    })

    return formattedData
}

const HEADCOUNT_FIELDS_TO_IGNORE = [
    'program_name',
    'group_name',
    'deliverable_name',
    'headcount_type',
    'is_active',
    'is_submitted',
    'previous_submitted_value',
    'previous_submitted_fte_month_value',
]

export const formatHeadcountAudits = (params: FormatterParameters) => {
    const { items, state, dispatch } = params
    const formattedData: any[] = []
    const planId = state.selectPlanData.selected.value || ''
    if (!planId) {
        return
    }
    const programs = state.programsByPlan.data[planId]
    const deliverables = state.deliverablesByPlan.data[planId]
    items.forEach((rawEntry) => {
        const rowProcessed = ignoreChange(rawEntry, HEADCOUNT_FIELDS_TO_IGNORE)
        if (rowProcessed.ignoreRow) {
            return
        }
        const entryProcessed = {
            ...rawEntry,
            differences: rowProcessed.differences,
            prev_state: rowProcessed.prev_state,
            next_state: rowProcessed.next_state,
        }
        const targetState =
            rawEntry.action !== ACTION_TYPES.DELETE
                ? entryProcessed.next_state
                : entryProcessed.prev_state
        const isFte = 'headcount_value' in targetState
        const objectName = `${getItemName(rawEntry)} ${isFte ? 'Headcount' : 'Effort'} Estimate`
        const actionObjectType = `${capitalizeFirstLetter(rawEntry.action)} ${capitalizeFirstLetter(rawEntry.object_type)}`
        formattedData.push({
            date: formatTimestampFromSortKey(rawEntry),
            program_id: rawEntry.program_id,
            program_name: programs?.get(rawEntry.program_id)?.program_name,
            deliverable_name: deliverables?.get(rawEntry.deliverable_id)?.deliverable_name,
            deliverable_id: rawEntry.deliverable_id,
            org_name: state.orgsMap.data.get(rawEntry.org_id)?.org_name,
            group_name: state.groupsMap.data.get(rawEntry.group_id)?.group_name,
            user_alias: rawEntry.user_alias,
            action: rawEntry.action,
            log_group: AUDIT_LOG_GROUPS.HEADCOUNT,
            action_formatted: `${capitalizeFirstLetter(rawEntry.action)}`,
            action_object_type: actionObjectType,
            revision_name: getRevisionNameFromState(rawEntry.revision_id, rawEntry.plan_id, state),
            on_details_click: () => onDetailsClick(entryProcessed, dispatch),
            differences: rowProcessed.differences,
            prev_state: rowProcessed.prev_state,
            next_state: rowProcessed.next_state,
            item_name: objectName,
            plan_id: rawEntry.plan_id,
            default_val: 0,
            fields_to_ignore: HEADCOUNT_FIELDS_TO_IGNORE,
            row_id: `${rawEntry.business_entity_id}_${rawEntry.timestamp_sort}`,
        })
    })

    return formattedData
}

const formatPlanRevisionAudits = (params: FormatterParameters) => {
    const formattedData: any[] = []
    const { items, state, dispatch } = params
    items.forEach((rawEntry) => {
        const rowProcessed = ignoreChange(rawEntry)
        if (rowProcessed.ignoreRow) {
            return
        }
        const entryProcessed = {
            ...rawEntry,
            differences: rowProcessed.differences,
            prev_state: rowProcessed.prev_state,
            next_state: rowProcessed.next_state,
        }
        const planMeta = state.plansMap.data.get(rawEntry.plan_id)
        const dateTime = formatTimestampFromSortKey(rawEntry)
        const planName = planMeta ? `${planMeta.plan_type} ${planMeta.year}` : 'Plan Not Found'
        const actionObjectType = `${capitalizeFirstLetter(rawEntry.action)} ${capitalizeFirstLetter(rawEntry.object_type)}`
        const objectName = `${capitalizeFirstLetter(rawEntry.object_type)} ${getItemName(rawEntry)}`
        formattedData.push({
            date: dateTime,
            plan_name: planName,
            user_alias: rawEntry.user_alias,
            action_object_type: actionObjectType,
            on_details_click: () => onDetailsClick(entryProcessed, dispatch),
            action: rawEntry.action,
            differences: rowProcessed.differences,
            prev_state: rowProcessed.prev_state,
            next_state: rowProcessed.next_state,
            item_name: objectName,
            plan_id: rawEntry.plan_id,
            row_id: `${rawEntry.business_entity_id}_${rawEntry.timestamp_sort}`,
        })
    })
    return formattedData
}

const formatProgramDeliverableAudits = (params: FormatterParameters) => {
    const { items, state, dispatch } = params
    const formattedData: any[] = []
    const planId = state.selectPlanData.selected.value || ''
    if (!planId) {
        return
    }
    const programs = state.programsByPlan.data[planId]
    items.forEach((rawEntry) => {
        const rowProcessed = ignoreChange(rawEntry)
        if (rowProcessed.ignoreRow) {
            return
        }
        const entryProcessed = {
            ...rawEntry,
            differences: rowProcessed.differences,
            prev_state: rowProcessed.prev_state,
            next_state: rowProcessed.next_state,
        }
        const dateTime = formatTimestampFromSortKey(rawEntry)
        const actionObjectType = `${capitalizeFirstLetter(rawEntry.action)} ${capitalizeFirstLetter(rawEntry.object_type)}`
        const objectName = `${capitalizeFirstLetter(rawEntry.object_type)} ${getItemName(rawEntry)}`

        formattedData.push({
            date: dateTime,
            user_alias: rawEntry.user_alias,
            action_object_type: actionObjectType,
            on_details_click: () => onDetailsClick(entryProcessed, dispatch),
            action: rawEntry.action,
            differences: rowProcessed.differences,
            prev_state: rowProcessed.prev_state,
            next_state: rowProcessed.next_state,
            item_name: objectName,
            plan_id: rawEntry.plan_id,
            program_name: programs?.get(rawEntry?.program_id)?.program_name || '',
            program_id: rawEntry?.program_id,
            revision_name: getRevisionNameFromState(rawEntry.revision_id, rawEntry.plan_id, state),
            row_id: `${rawEntry.business_entity_id}_${rawEntry.timestamp_sort}`,
        })
    })
    return formattedData
}

export const formatDataForState = (params: FormatterParameters) => {
    const { activeTabId } = params.state
    switch (activeTabId) {
        case AUDIT_LOG_GROUPS.HEADCOUNT:
            return formatHeadcountAudits(params)
        case AUDIT_LOG_GROUPS.SPEND:
            return formatSpendAudits(params)
        case AUDIT_LOG_GROUPS.PROGRAM_DELIVERABLE:
            return formatProgramDeliverableAudits(params)
        case AUDIT_LOG_GROUPS.PLAN_REVISION:
            return formatPlanRevisionAudits(params)
        default:
            return []
    }
}
