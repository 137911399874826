import { Box, Button, Form, Header, SpaceBetween } from '@amzn/awsui-components-react'
import { Avatar } from '@mui/material'
import { convertToLocalTime } from '../../common/Util'
import { ROLES } from '../../../role'
import _ from 'lodash'
import {
    COMMENT_PANEL_MODE,
    COMMENT_PANEL_PROG_DELI_MAX_TEXT_LENGTH,
    COMMENT_PANEL_ROLE_NAME_MAX_TEXT_LENGTH,
    DEFAULT_SELECTED_DELIVERABLE,
    USER_ROLES,
    COMMENT_DIV_CLASS,
    USER_ROLE_OBJECT_TYPES,
    FILTER_PAYLOAD_TYPES,
    Comment,
    CommentFilter,
} from './Constant'
import { generateSplitPanelItemBadge } from '../program/SandboxUtils'
import { HISTORY_BADGE_BLUE, HISTORY_BADGE_GREY } from '../summary/SummaryUtil'
import { Dispatch, SetStateAction } from 'react'
import { AxiosInstance } from 'axios'

const AWS_COLOR_BLUE_600 = '#006ce0'
const DELETED_COMMENT_COLOR = '#5F6B7A'

const COMMENT_DIV_MARGIN_EDIT_MODE = '25vh'
const COMMENT_DIV_MARGIN_VIEW_MODE = '5vh'

export const generateCommentDivId = (commentId: string) => {
    return `comment-form-${commentId}`
}

export const setSidePanelScroll = (mode) => {
    const sidePanel = document.querySelector('section[id^="split-panel"]') as HTMLElement
    if (sidePanel) {
        // we have to remove aria-hidden, otherwise an error is thrown
        // bc aria-hidden is not accessibility-friendly
        // removing aria-hidden has no effect on the UI
        // but an error is thrown which blocks us from updating overflow-Y
        // when history panel is open
        sidePanel.removeAttribute('aria-hidden')
        sidePanel.style.overflowY = mode !== COMMENT_PANEL_MODE.DISABLED ? 'hidden' : 'auto'
    }
}

export const setCommentsDivBottomMargin = (drawerMode: COMMENT_PANEL_MODE) => {
    const commentsDrawer = document.getElementById(COMMENT_DIV_CLASS)
    if (commentsDrawer) {
        commentsDrawer.style.marginBottom =
            drawerMode === COMMENT_PANEL_MODE.EDIT
                ? COMMENT_DIV_MARGIN_EDIT_MODE
                : COMMENT_DIV_MARGIN_VIEW_MODE
    }
}

export const generateDeletedCommentText = (commentTextArr) => {
    const deletedCommentPrefix = [
        { insert: 'Comment has been deleted by author\n', attributes: { bold: true } },
    ]

    return deletedCommentPrefix.concat(
        commentTextArr.map((commentLine: any) => {
            if (!('attributes' in commentLine)) {
                commentLine['attributes'] = {}
            }
            commentLine['attributes']['strike'] = true
            commentLine['attributes']['color'] = DELETED_COMMENT_COLOR
            return commentLine
        }),
    )
}

export const generateInactiveCommentText = (commentTextArr, isInactiveProgram: boolean) => {
    const inactiveCommentPrefix = [
        {
            insert: `Inactive ${isInactiveProgram ? 'program' : 'deliverable'} comment
`,
            attributes: { bold: true },
        },
    ]

    return inactiveCommentPrefix.concat(
        commentTextArr.map((commentLine: any) => {
            if (!('attributes' in commentLine)) {
                commentLine['attributes'] = {}
            }
            commentLine['attributes']['color'] = DELETED_COMMENT_COLOR
            return commentLine
        }),
    )
}

export const generateCommentForms = (
    apiClient: AxiosInstance,
    userAlias: string,
    comments: Comment[],
    refreshComments: () => void,
    programMapName: { [key: string]: string },
    deliverableMap: { [key: string]: any },
    allOrgs: any[],
    allGroups: any[],
) => {
    const generateComment = (comment) => {
        const getUserRoleName = (comment) => {
            let result = ''
            switch (comment.user_role) {
                case ROLES.ADMIN:
                    break
                case ROLES.STL:
                    result = programMapName[comment.stl_parent_program_id]
                    break
                case ROLES.ORG_MANAGER:
                    result = allOrgs.find((org) => org.org_id === comment.org_id)?.org_name
                    break
                case ROLES.GROUP_MANAGER:
                    result = allGroups.find(
                        (group) => group.group_id === comment.group_id,
                    )?.group_name
                    break
                default:
                    break
            }
            return result ? `${result} - ${comment.user_role}` : comment.user_role
        }
        return (
            <Box key={comment.comment_id} padding={{ vertical: 'xxs' }}>
                <SpaceBetween size={'xxs'} direction={'vertical'}>
                    <Form
                        header={
                            <SpaceBetween size={'s'} direction={'vertical'}>
                                <SpaceBetween size={'xxs'} direction={'horizontal'}>
                                    {generateSplitPanelItemBadge(
                                        programMapName[comment.parent_program_id],
                                        HISTORY_BADGE_GREY,
                                        COMMENT_PANEL_PROG_DELI_MAX_TEXT_LENGTH,
                                    )}
                                    {generateSplitPanelItemBadge(
                                        deliverableMap[comment.deliverable_id]?.deliverable_name,
                                        HISTORY_BADGE_GREY,
                                        COMMENT_PANEL_PROG_DELI_MAX_TEXT_LENGTH,
                                    )}
                                    {generateSplitPanelItemBadge(
                                        getUserRoleName(comment),
                                        HISTORY_BADGE_BLUE,
                                        COMMENT_PANEL_ROLE_NAME_MAX_TEXT_LENGTH,
                                    )}
                                </SpaceBetween>
                                <Header
                                    actions={
                                        <SpaceBetween size={'xxs'} direction={'horizontal'}>
                                            <Box float={'right'} variant={'small'}>
                                                {convertToLocalTime(
                                                    new Date(
                                                        parseInt(comment.update_timestamp),
                                                    ).toISOString(),
                                                )}
                                            </Box>
                                            {userAlias === comment.user_alias && (
                                                <Button
                                                    iconName='remove'
                                                    variant='icon'
                                                    disabled={comment?.deleted}
                                                    onClick={() => {
                                                        apiClient
                                                            .delete(
                                                                `/comment/${comment.comment_id}`,
                                                            )
                                                            .then(() => refreshComments())
                                                            .catch((err) => {
                                                                console.error(err)
                                                            })
                                                    }}
                                                />
                                            )}
                                        </SpaceBetween>
                                    }
                                >
                                    <SpaceBetween size={'xxs'} direction={'horizontal'}>
                                        <Avatar
                                            sx={{
                                                marginTop: 0.5,
                                                height: 25,
                                                width: 25,
                                                bgcolor: AWS_COLOR_BLUE_600,
                                            }}
                                        />
                                        <Box margin={{ vertical: 'xs' }} variant={'h4'}>
                                            {comment.user_alias}
                                        </Box>
                                    </SpaceBetween>
                                </Header>
                            </SpaceBetween>
                        }
                    >
                        <div
                            id={generateCommentDivId(comment.comment_id)}
                            className={'comment-drawer-posted-comments'}
                        ></div>
                    </Form>
                    <hr />
                </SpaceBetween>
            </Box>
        )
    }

    // Sort comments so that the latest comments are at the top in the comments panel
    const sortedComments = comments.sort((a, b) => b.update_timestamp - a.update_timestamp)
    return sortedComments.map((comment) => generateComment(comment))
}

export const generateCommentSubMenu = (userRolesMetadata, handleCellClick, selectedPlan) => {
    const result: any[] = []
    const hasAdminRole = userRolesMetadata.ROLES?.find((role) => role.id === ROLES.ADMIN)
    if (hasAdminRole) {
        const adminRoleMetadata = {
            object_name: ROLES.ADMIN,
            object_id: ROLES.ADMIN,
            role_id: ROLES.ADMIN,
            role_name: ROLES.ADMIN,
        }
        result.push({
            name: ROLES.ADMIN,
            action: (params) => {
                handleCellClick(params, adminRoleMetadata)
            },
            // Polaris ButtonDropdown
            text: ROLES.ADMIN,
            id: JSON.stringify(adminRoleMetadata),
        })
    }

    Object.keys(USER_ROLES).forEach((role) => {
        if (!_.isEmpty(userRolesMetadata[role])) {
            userRolesMetadata[role].forEach((roleMetadata) => {
                if (role === 'USER_PROGRAMS') {
                    const currPlanId = roleMetadata.object_id.split('#')[0]
                    if (currPlanId !== selectedPlan?.plan_id) {
                        return
                    }
                }
                if (
                    (role === 'USER_ORGS' || role === 'USER_REGISTERED_GROUPS') &&
                    !roleMetadata?.is_active
                ) {
                    return
                }

                const roleName = `${roleMetadata.object_name} - ${USER_ROLES[role]}`
                const fullRoleMetadata = {
                    ...roleMetadata,
                    org_id:
                        roleMetadata?.object_type === USER_ROLE_OBJECT_TYPES.ORG
                            ? roleMetadata.object_id
                            : roleMetadata?.org_id,
                    role_id: USER_ROLES[role].replace(' ', ''),
                    role_name: roleName,
                }
                result.push({
                    name: roleName,
                    action: (params) => {
                        handleCellClick(params, fullRoleMetadata)
                    },
                    // Polaris ButtonDropdown
                    text: roleName,
                    id: JSON.stringify(fullRoleMetadata),
                })
            })
        }
    })

    return result
}

export const addCommentMenuItems = (
    isProgram: boolean,
    userRolesMetadata,
    setCommentMode,
    setIsSidePanelVisible,
    setSelectedProgram,
    setSelectedRole,
    setSelectedDeliverable,
    setCommentDeliverableOptions,
    selectedPlan,
) => {
    const getSelectedCellData = (params) => {
        const { node } = params

        if (isProgram) {
            const parentProgramId = node.data
                ? node.data.parent_program_id
                : node?.allLeafChildren[0]?.data.parent_program_id
            const programName = node.data
                ? node.data.program_name
                : node?.allLeafChildren[0]?.data.program_name
            return {
                program_name: programName ?? '',
                parent_program_id: parentProgramId ?? '',
                deliverable_name: '',
                deliverable_id: '',
            }
        }

        // deliverable child cell
        return {
            program_name: node.data?.program_name,
            parent_program_id: node.data.parent_program_id,
            deliverable_name: node.data.deliverable_name.name,
            deliverable_id: node.data.deliverable_id,
        }
    }

    const handleCellClick = (params, roleMetadata) => {
        const selectedCellMetadata = getSelectedCellData(params)
        let deliverableChildren: any[] = []

        setCommentMode(COMMENT_PANEL_MODE.EDIT)
        setIsSidePanelVisible(true)
        setSelectedProgram({
            label: selectedCellMetadata.program_name,
            value: selectedCellMetadata.parent_program_id,
        })
        setSelectedRole({
            label: roleMetadata.role_name,
            value: JSON.stringify(roleMetadata),
        })
        if (selectedCellMetadata.deliverable_id) {
            setSelectedDeliverable({
                label: selectedCellMetadata.deliverable_name,
                value: selectedCellMetadata.deliverable_id,
            })
            deliverableChildren = params.node.parent?.allLeafChildren
        } else {
            setSelectedDeliverable(DEFAULT_SELECTED_DELIVERABLE)
            deliverableChildren = params.node?.data?.deliverables
                ? params.node.data.deliverables
                : params.node.allLeafChildren
        }
        setCommentDeliverableOptions(
            deliverableChildren.map((deliv) => {
                return {
                    ...deliv,
                    description: '',
                    label: deliv?.deliverable_name
                        ? deliv.deliverable_name
                        : deliv.data.deliverable_name.name,
                    value: deliv?.deliverable_id ? deliv.deliverable_id : deliv.data.deliverable_id,
                }
            }),
        )
    }

    return {
        name: `Add Comment`,
        subMenu: [
            {
                name: `Your Roles<hr/>`,
                suppressCloseOnSelect: true,
            },
            ...generateCommentSubMenu(userRolesMetadata, handleCellClick, selectedPlan),
        ],
    }
}

export const getProgramDeliverableMapByParentProgramGlobalAttributes = (globalAttributes) => {
    const programMapName = {}
    const deliverableMap = {}
    globalAttributes.forEach((attr) => {
        programMapName[attr.parent_program_id] = attr.program_name
        attr.global_deliverables.forEach((deliv) => {
            deliverableMap[deliv.deliverable_id] = deliv
        })
    })

    return [programMapName, deliverableMap]
}

export const getParentProgramGlobalAttributes = (
    apiClient: AxiosInstance,
    plan,
    onParentProgramGlobalAttributesChange: Dispatch<SetStateAction<any[]>>,
) => {
    const opPlanEncodeURL = encodeURIComponent(`${plan?.value.plan_type}#${plan?.value.year}`)
    apiClient
        .get(`/op_plan/${opPlanEncodeURL}/parent_program_global_attributes`)
        .then((res) => {
            onParentProgramGlobalAttributesChange(res.data)
        })
        .catch((error) => {
            console.error(error)
        })
}

export const generateFilterPayload = (commentFilter: CommentFilter[], selectedPlan) => {
    const filterPayload: any = {
        program_ids: [],
        deliverable_ids: [],
        org_ids: [],
        group_ids: [],
        user_alias: [],
        op_plans: [],
    }

    commentFilter.forEach((filter: CommentFilter) => {
        const filterType = filter.type
        const filterId = filter.id
        switch (filterType) {
            case FILTER_PAYLOAD_TYPES.ORG: {
                filterPayload.org_ids.push(filterId)
                return
            }
            case FILTER_PAYLOAD_TYPES.GROUP: {
                filterPayload.group_ids.push(filterId)
                return
            }
            case FILTER_PAYLOAD_TYPES.PROGRAM: {
                filterPayload.program_ids.push(filterId)
                return
            }
            case FILTER_PAYLOAD_TYPES.DELIVERABLE: {
                filterPayload.deliverable_ids.push(filterId)
                return
            }
            case FILTER_PAYLOAD_TYPES.USER_ALIAS: {
                filterPayload.user_alias.push(filterId)
                return
            }
            default: {
                console.error(`filter_payload_type ${filterId} not found`)
                return
            }
        }
    })

    filterPayload.op_plans.push(`${selectedPlan?.plan_type}#${selectedPlan?.year}`)
    return filterPayload
}

export const generateInitialCommentFilters = (userRoles, selectedPlan) => {
    const result: CommentFilter[] = []
    if (_.isEmpty(selectedPlan) || !userRoles.length) {
        return result
    }

    // select the first role to set filtering for the user
    const userRole = userRoles[0]
    // Admin can see all the comments and need not filter on login
    if (userRole.label === USER_ROLES.USER_ADMIN) {
        return result
    }

    const userRoleValue = JSON.parse(userRole.value)
    if (!userRoleValue.object_id || !userRoleValue.object_type) {
        console.error(`Could not parse user role value for ${userRoles[0]}`)
        return result
    }

    const role: USER_ROLE_OBJECT_TYPES = userRoleValue.object_type
    const id: string = userRoleValue.object_id
    if (role === USER_ROLE_OBJECT_TYPES.ORG) {
        result.push({
            label: userRoleValue.object_name,
            type: FILTER_PAYLOAD_TYPES.ORG,
            id: id,
        })
    } else if (role === USER_ROLE_OBJECT_TYPES.GROUP) {
        result.push({
            label: userRoleValue.object_name,
            type: FILTER_PAYLOAD_TYPES.GROUP,
            id: id,
        })
    } else if (role === USER_ROLE_OBJECT_TYPES.PROGRAM) {
        result.push({
            label: userRoleValue.object_name,
            type: FILTER_PAYLOAD_TYPES.PROGRAM,
            id: userRoleValue.parent_object_id,
        })
    }

    return result
}

export const formatCommentPanelSearchOptions = (items, label, id_label, type) => {
    return items
        .flatMap((item) => {
            if (item.archived) {
                return []
            }

            return {
                label: label ? item[label] : item,
                type: type,
                id: id_label ? item[id_label] : item,
            }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
}

export const getUserRoleSelections = (userRolesMetadata, selectedPlan) => {
    return generateCommentSubMenu(userRolesMetadata, () => {}, selectedPlan)
        .map((role) => {
            return {
                label: role.name,
                value: role.id,
            }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
}
