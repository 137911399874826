import _ from 'lodash'
import IsOpGoalPriorityBadge from '../reusable/IsOpGoalPriortyBadge'

import { ModuleRegistry } from 'ag-grid-community'
import { ClientSideRowModelModule } from 'ag-grid-community'
import { ServerSideRowModelModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { ExcelExportModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { SetFilterModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { InfiniteRowModelModule } from 'ag-grid-community'
import './SummaryView.scss'
import { LicenseManager } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import { RowGroupingModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { CustomCellRendererProps } from 'ag-grid-react'

LicenseManager.setLicenseKey(agGridLicense)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

export default (props: CustomCellRendererProps) => {
    const { node } = props

    let priority, isOpGoal
    if (node.group && node.level >= 0) {
        const children = node?.allLeafChildren
        if (!_.isEmpty(children)) {
            priority = children[0].data['priority']?.toUpperCase()
            isOpGoal = children[0].data['is_op_goal']
        }
    }

    return (
        <div>
            {node.group && (
                <>
                    {!node.footer && (
                        <IsOpGoalPriorityBadge isOpGoal={isOpGoal} priority={priority} />
                    )}
                </>
            )}
            {node.rowPinned == 'bottom' && <></>}
        </div>
    )
}
