import { DateRangePickerProps, FlashbarProps, SelectProps } from '@amzn/awsui-components-react'
import { useEffect, useReducer } from 'react'
import { useAppContext } from '../../../context'
import useStore from '../../Store'
import { formatPlanOptionsWithData } from '../reusable/Utils'
import { AUDIT_LOG_GROUPS, BUSINESS_ENTITY_ID, OBJECT_TYPES } from './Constant'
import { EMPTY_PLAN_SELECTION } from '../../Constant'
import { FilterModel } from 'ag-grid-community'
import { buildLogGroupKeyFromState } from './AuditUtil'
import { addArchivedItems, fetchArchivedItems } from './ArchiveUtil'
import { formatRevisionNameForColumnDisplay } from './audit_tables/AuditFormatters'
import { sortPlanOptionsWithData } from '../program/ProgramSharedUtils'

interface AuditTabGridState {
    loading: boolean
    flashBarItems: FlashbarProps.MessageDefinition[]
}

export interface AuditTabPlanDataState {
    startTimestampFetched: string | null
    endTimestampFetched: string | null
    lastQueryKeyValues: string
    filterModel: FilterModel
    currentPageIndex: number
    totalPages: number
    queryKey: string
    queryKeyValues: string
    rowData: any[]
    nextToken: string
    visibleRows: number
}

const generateDefaultAuditTabGridState = () => {
    return {
        loading: false,
        flashBarItems: [],
    }
}

const generateDefaultLogGroupPlanDataState = (selectedBusinessEntityId) => {
    return {
        rowData: [],
        nextToken: '',
        startTimestampFetched: null,
        endTimestampFetched: null,
        lastQueryKeyValues: '',
        queryKey: BUSINESS_ENTITY_ID,
        queryKeyValues: selectedBusinessEntityId,
        filterModel: {},
        currentPageIndex: 1,
        totalPages: 1,
        visibleRows: 0,
    }
}

const generateDefaultSharedDataState = (defaultVal) => {
    return {
        data: defaultVal,
        loading: true,
    }
}

interface DropdownState {
    options: SelectProps.Option[]
    selected: SelectProps.Option
}

interface SharedDataState {
    data: any
    loading: boolean
}

export interface AuditState {
    gridApi: any
    plansMap: SharedDataState
    orgsMap: SharedDataState
    revisionsMap: SharedDataState
    archivedRevisionsByPlan: SharedDataState
    groupsMap: SharedDataState
    programsByPlan: SharedDataState
    deliverablesByPlan: SharedDataState
    userAliases: SharedDataState
    selectPlanData: DropdownState
    startTimestamp: string
    endTimestamp: string
    activeTabId: string
    clickedItem: any
    isDetailsModalVisible: boolean
    dateRangeValue: DateRangePickerProps.Value | null
    [AUDIT_LOG_GROUPS.HEADCOUNT]: AuditTabGridState
    [AUDIT_LOG_GROUPS.PROGRAM_DELIVERABLE]: AuditTabGridState
    [AUDIT_LOG_GROUPS.PLAN_REVISION]: AuditTabGridState
    [AUDIT_LOG_GROUPS.SPEND]: AuditTabGridState
    auditTabDataStates: Map<string, AuditTabPlanDataState> // key is logGroup_planID
    selectedPageSize: number
}

export enum AUDIT_STATE_ACTIONS {
    SET_SINGLE = 'set_single',
    SET_MANY = 'set_many',
    SET_LOG_GROUP_PLAN_DATA = 'set_log_group_plan_data',
    SET_EMPTY_OPTIONS = 'set_empty_options',
    SET_DATE_RANGE = 'set_date_range',
    RESET_STATE = 'reset_state',
    SET_PAGE_SIZE = 'set_page_size',
    COMPUTE_TOTAL_PAGES_FOR_LOG = 'compute_total_pages_for_log',
    ADD_ARCHIVE_ITEMS = 'add_archive_items',
}

const reducer = (state, action) => {
    const headcountGroups = [
        AUDIT_LOG_GROUPS.HEADCOUNT,
        AUDIT_LOG_GROUPS.PLAN_REVISION,
        AUDIT_LOG_GROUPS.SPEND,
        AUDIT_LOG_GROUPS.PROGRAM_DELIVERABLE,
    ]
    const newState = { ...state }
    switch (action.type) {
        case AUDIT_STATE_ACTIONS.SET_SINGLE:
            Object.keys(action.payload).forEach((key) => {
                newState[key] = action.payload[key]
            })
            return newState
        case AUDIT_STATE_ACTIONS.SET_MANY:
            // for setting complex keys w/ 1+ attributes
            // payload is an object mapping a state key to a set of attributes
            // example: payload: {HEADCOUNT: {loading: false, rowData: []}, orgsMap: {loading: false}}
            Object.keys(action.payload).forEach((key) => {
                newState[key] = { ...newState[key], ...action.payload[key] }
            })
            return newState
        case AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA:
            const key = action.log_group_key
            const oldKeyState = state.auditTabDataStates.get(key) || {}
            const newPayload = { ...oldKeyState, ...action.payload }
            newState.auditTabDataStates.set(key, newPayload)
            return newState
        case AUDIT_STATE_ACTIONS.SET_PAGE_SIZE:
            state.gridApi.setGridOption('paginationPageSize', action.new_page_size)
            const newPageTotal = state.gridApi.paginationGetTotalPages()
            const newLogStates: [string, AuditTabPlanDataState][] = []
            state.auditTabDataStates.forEach((dataState, key, map) => {
                newLogStates.push([
                    key,
                    {
                        ...dataState,
                        currentPageIndex: 1,
                        totalPages:
                            key === action.log_group_key ? newPageTotal : dataState.totalPages,
                    },
                ])
            })
            return {
                ...newState,
                auditTabDataStates: new Map(newLogStates),
                selectedPageSize: action.new_page_size,
            }
        case AUDIT_STATE_ACTIONS.COMPUTE_TOTAL_PAGES_FOR_LOG:
            const currentLogGroupState = newState.auditTabDataStates.get(action.log_group_key)
            if (!currentLogGroupState) {
                return newState
            }
            newState.auditTabDataStates.set(action.log_group_key, {
                ...currentLogGroupState,
                totalPages: state.gridApi.paginationGetTotalPages(),
                visibleRows: state.gridApi.getDisplayedRowCount(),
            })
            return newState
        case AUDIT_STATE_ACTIONS.SET_EMPTY_OPTIONS:
            return {
                ...state,
                selectPlanData: {
                    selected: EMPTY_PLAN_SELECTION,
                    options: [],
                },
            }
        case AUDIT_STATE_ACTIONS.SET_DATE_RANGE:
            return {
                ...state,
                startTimestamp: action.startTimestamp,
                endTimestamp: action.endTimestamp,
                dateRangeValue: action.dateRangeValue,
            }
        case AUDIT_STATE_ACTIONS.RESET_STATE:
            const resetState = {
                ...state,
                clickedItem: {},
                isDetailsModalVisible: false,
                plansMap: generateDefaultSharedDataState(new Map()),
                orgsMap: generateDefaultSharedDataState(new Map()),
                revisionsMap: generateDefaultSharedDataState(new Map()),
                groupsMap: generateDefaultSharedDataState(new Map()),
                programsByPlan: generateDefaultSharedDataState({}),
                deliverablesByPlan: generateDefaultSharedDataState({}),
                auditTabDataStates: new Map(),
                startTimestamp: '',
                endTimestamp: new Date().getTime().toString(),
                dateRangeValue: null,
            }
            headcountGroups.forEach((logGroup) => {
                resetState[logGroup] = {
                    ...state[logGroup],
                    loading: state.activeTabId === logGroup,
                }
            })

            return resetState
        case AUDIT_STATE_ACTIONS.ADD_ARCHIVE_ITEMS:
            const payload = action.payload
            const isKeyedByPlan = [
                OBJECT_TYPES.PROGRAM,
                OBJECT_TYPES.DELIVERABLE,
                OBJECT_TYPES.ARCHIVED_REVISION,
            ].includes(payload.objectType)
            const fullData = newState[payload.mapKey].data
            const currentMap = isKeyedByPlan ? fullData[payload.planId] : fullData
            const itemProcessor =
                payload.objectType === OBJECT_TYPES.ARCHIVED_REVISION
                    ? (rev) => ({
                          ...rev,
                          revision_name: formatRevisionNameForColumnDisplay(rev, true),
                          archived: true,
                      })
                    : undefined
            const updatedMap = addArchivedItems(
                currentMap ?? new Map(),
                payload.archiveData,
                itemProcessor,
            )
            return {
                ...newState,
                [payload.mapKey]: {
                    data: !isKeyedByPlan
                        ? updatedMap
                        : { ...fullData, [payload.planId]: updatedMap },
                    loading: false,
                },
            }
        default:
            return state
    }
}

const generateInitialState = (): AuditState => {
    return {
        plansMap: generateDefaultSharedDataState(new Map()),
        orgsMap: generateDefaultSharedDataState(new Map()),
        revisionsMap: generateDefaultSharedDataState(new Map()),
        archivedRevisionsByPlan: generateDefaultSharedDataState({}),
        groupsMap: generateDefaultSharedDataState(new Map()),
        programsByPlan: generateDefaultSharedDataState({}),
        deliverablesByPlan: generateDefaultSharedDataState({}),
        userAliases: generateDefaultSharedDataState({}),
        selectPlanData: { selected: EMPTY_PLAN_SELECTION, options: [] },
        [AUDIT_LOG_GROUPS.HEADCOUNT]: generateDefaultAuditTabGridState(),
        [AUDIT_LOG_GROUPS.PROGRAM_DELIVERABLE]: generateDefaultAuditTabGridState(),
        [AUDIT_LOG_GROUPS.PLAN_REVISION]: generateDefaultAuditTabGridState(),
        [AUDIT_LOG_GROUPS.SPEND]: generateDefaultAuditTabGridState(),
        auditTabDataStates: new Map(),
        startTimestamp: '',
        endTimestamp: new Date().getTime().toString(),
        activeTabId: AUDIT_LOG_GROUPS.HEADCOUNT,
        dateRangeValue: null,
        selectedPageSize: 50,
        clickedItem: {},
        isDetailsModalVisible: false,
        gridApi: null,
    }
}

const addRevisionNameToRevision = (rev) => {
    return {
        ...rev,
        revision_name: formatRevisionNameForColumnDisplay(rev),
    }
}

export function useAuditState() {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)

    const [state, dispatch] = useReducer(reducer, selectedBusinessEntity, generateInitialState)

    const fetchPlansRevisionsByBusinessEntity = () => {
        apiClient
            .get(`/plan/business-entity/${selectedBusinessEntity?.business_entity_id}?year=`)
            .then((res) => {
                const allPlans = res.data
                const formattedPlanOptions = sortPlanOptionsWithData(
                    allPlans.map((plan) => formatPlanOptionsWithData(plan)),
                )
                if (formattedPlanOptions.length) {
                    const revisionIdRevisionList: any[] = []
                    const planIdPlanList: any[] = []

                    allPlans.forEach((plan) => {
                        const planRevisions = plan?.revisions ?? []
                        planIdPlanList.push([
                            plan.plan_id,
                            {
                                ...plan,
                                revisions: planRevisions.map((rev) =>
                                    addRevisionNameToRevision(rev),
                                ),
                            },
                        ])
                        plan.revisions.forEach((rev) => {
                            revisionIdRevisionList.push([
                                rev.revision_id,
                                addRevisionNameToRevision(rev),
                            ])
                        })
                    })
                    dispatch({
                        type: AUDIT_STATE_ACTIONS.SET_MANY,
                        payload: {
                            selectPlanData: {
                                options: formattedPlanOptions,
                                selected: formattedPlanOptions[0],
                            },

                            plansMap: { data: new Map(planIdPlanList), loading: false },

                            revisionsMap: {
                                data: new Map(revisionIdRevisionList),
                                loading: false,
                            },
                        },
                    })
                } else {
                    dispatch({
                        type: AUDIT_STATE_ACTIONS.SET_EMPTY_OPTIONS,
                    })
                }
            })
            .catch((err) => {
                console.error(err)
                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_EMPTY_OPTIONS,
                })
            })
    }

    const fetchOrgsByBusinessEntity = () => {
        apiClient
            .get(`/falcon/business-entity/${selectedBusinessEntity.business_entity_id}/orgs`)
            .then((res) => {
                const orgsMetadata = res.data
                const orgIdOrgList: any[] = []
                orgsMetadata.forEach((org) => {
                    orgIdOrgList.push([org.org_id, org])
                })
                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_MANY,
                    payload: {
                        orgsMap: {
                            data: new Map(orgIdOrgList),
                        },
                    },
                })
                fetchArchivedItems(apiClient, dispatch, OBJECT_TYPES.ORG, 'orgsMap')
            })
            .catch((err) => {
                console.error(err)
                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_MANY,
                    payload: {
                        orgsMap: {
                            loading: false,
                        },
                    },
                })
            })
    }

    const fetchGroupsByBusinessEntity = () => {
        apiClient
            .get(
                `/business_entity/${selectedBusinessEntity.business_entity_id}/groups?filter_groups=false`,
            )
            .then((res) => {
                const groupsMetadata = res.data
                const groupIdGroupList: any[] = []
                Object.keys(groupsMetadata).forEach((groupName) => {
                    groupsMetadata[groupName].forEach((group) => {
                        groupIdGroupList.push([group.group_id, group])
                    })
                })
                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_MANY,
                    payload: {
                        groupsMap: {
                            data: new Map(groupIdGroupList),
                            loading: false,
                        },
                    },
                })
            })
            .catch((err) => console.error(err))
    }

    const fetchUserAliasesByPlan = (planId: string) => {
        const numberSignEncodeURI = encodeURIComponent('#')
        apiClient
            .get(
                `/audit_filters/plan/${planId}/filter_sort/${state.activeTabId}${numberSignEncodeURI}user_alias`,
            )
            .then((res) => {
                const auditFilterUserAliases = res.data
                const userAliases: string[] = []
                auditFilterUserAliases.forEach((auditFilter) => {
                    const userAlias = auditFilter.filter_sort.split('#')[2]
                    userAliases.push(userAlias)
                })
                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_MANY,
                    payload: {
                        userAliases: {
                            data: userAliases,
                            loading: false,
                        },
                    },
                })
            })
            .catch((err) => console.error(err))
    }

    useEffect(() => {
        if (!selectedBusinessEntity) {
            return
        }

        fetchPlansRevisionsByBusinessEntity()
        fetchOrgsByBusinessEntity()
        fetchGroupsByBusinessEntity()
        dispatch({
            type: AUDIT_STATE_ACTIONS.RESET_STATE,
            business_entity_id: selectedBusinessEntity.business_entity_id,
        })
    }, [selectedBusinessEntity])

    const fetchLocalProgramsByBusinessEntity = (planId: string) => {
        dispatch({
            type: AUDIT_STATE_ACTIONS.SET_MANY,
            payload: {
                programsByPlan: {
                    loading: true,
                },
            },
        })
        apiClient
            .get(`plan/${planId}/local_programs`)
            .then((res) => {
                const programsMetadata = res.data
                const programIdProgramList: any[] = []
                programsMetadata.forEach((prog) => {
                    programIdProgramList.push([prog.program_id, prog])
                })
                const tempProgramsByPlan = {
                    ...state.programsByPlan.data,
                    [planId]: new Map(programIdProgramList),
                }

                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_MANY,
                    payload: {
                        programsByPlan: {
                            data: tempProgramsByPlan,
                        },
                    },
                })
                // loading state will be managed here
                fetchArchivedItems(
                    apiClient,
                    dispatch,
                    OBJECT_TYPES.PROGRAM,
                    'programsByPlan',
                    planId,
                )
            })
            .catch((err) => {
                console.error(err)
                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_MANY,
                    payload: {
                        programsByPlan: {
                            loading: false,
                        },
                    },
                })
            })
    }

    const fetchDeliverablesByBusinessEntity = (planId: string) => {
        dispatch({
            type: AUDIT_STATE_ACTIONS.SET_MANY,
            payload: {
                deliverablesByPlan: {
                    loading: true,
                },
            },
        })
        apiClient
            .get(`plan/${planId}/all-deliverables`)
            .then((res) => {
                const deliverableMetadata: { [p: string]: any } = res.data || {}
                const deliverableIdList: [string, any][] = Object.entries(deliverableMetadata)
                const tempDelisByPlan = {
                    ...state.deliverablesByPlan.data,
                    [planId]: new Map(deliverableIdList),
                }

                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_MANY,
                    payload: {
                        deliverablesByPlan: {
                            data: tempDelisByPlan,
                        },
                    },
                })
                // loading state will be managed here
                fetchArchivedItems(
                    apiClient,
                    dispatch,
                    OBJECT_TYPES.DELIVERABLE,
                    'deliverablesByPlan',
                    planId,
                )
            })
            .catch((err) => {
                console.error(err)
                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_MANY,
                    payload: {
                        deliverablesByPlan: {
                            loading: false,
                        },
                    },
                })
            })
    }

    useEffect(() => {
        const selectedPlanId: string = state.selectPlanData?.selected?.value || ''
        if (!selectedPlanId) {
            return
        }

        if (!(selectedPlanId in state.programsByPlan.data)) {
            fetchLocalProgramsByBusinessEntity(selectedPlanId)
            fetchDeliverablesByBusinessEntity(selectedPlanId)
        }
        fetchUserAliasesByPlan(selectedPlanId)

        if (!(selectedPlanId in state.archivedRevisionsByPlan.data)) {
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_MANY,
                payload: {
                    archivedRevisionsByPlan: {
                        loading: true,
                    },
                },
            })
            fetchArchivedItems(
                apiClient,
                dispatch,
                OBJECT_TYPES.REVISION,
                'archivedRevisionsByPlan',
                selectedPlanId,
            )
        }
        const auditKey = buildLogGroupKeyFromState(state)
        if (!state.auditTabDataStates.has(auditKey)) {
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_LOG_GROUP_PLAN_DATA,
                payload: generateDefaultLogGroupPlanDataState(
                    selectedBusinessEntity.business_entity_id,
                ),
                log_group_key: auditKey,
            })
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_MANY,
                payload: { [state.activeTabId]: { loading: true } },
            })
        }
    }, [state.selectPlanData?.selected?.value, state.activeTabId])

    return {
        state,
        dispatch,
    }
}
