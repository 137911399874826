import { ExcelRow, ExcelCell, ExcelFont } from '@amzn/ag-bird/src/ag-grid-enterprise'
import _ from 'lodash'
import { MONTHLY_EXPENDITURE_FIELDS } from '../summary/NewSpendSummaryUtils'
const DEFAULT_EMPTY_CELL: ExcelCell = { data: { value: '', type: 'String' } }
export const hiddenColumnDefs: any[] = [
    {
        field: 'org_name_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'Org',
    },
    {
        field: 'group_name_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'Group',
    },
    {
        field: 'program_name_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'Program',
    },
    {
        field: 'program_priority_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'PP',
    },
    {
        field: 'deliverable_name_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'Deliverable',
    },
    {
        field: 'deliverable_priority_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'DP',
    },
    {
        field: 'cost_centre_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'Cost Centre',
    },
    {
        field: 'updated_by_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'Updated By',
    },
    {
        field: 'updated_at_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'Updated At',
    },
    {
        field: 'hc_export',
        hide: true,
        suppressToolPanel: true,
        sortable: false,
        headerName: 'Headcount',
    },
]
const getHiddenExportFields = () => {
    return hiddenColumnDefs.map((colDef) => colDef.field)
}

const getFlatDataForRowNode = (gridApi, isEffortEstimate) => {
    let exportData: ExcelRow[] = []
    gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
        if (!rowNode.footer && !rowNode.group && rowNode?.data) {
            const rowData = rowNode?.data ?? {}
            const flattenedRows: ExcelRow[] = []
            // if exporting from Plan tab, hc will be saved under {org_id}_ff, {org_id}_ct columns
            // otherwise, {org_id}_{group_id}_ff, {org_id}_{group_id}_ct
            const dataToExport = rowData.export ?? []
            dataToExport.forEach((hcMeta) => {
                const cellMetadata: ExcelCell[] = [
                    hcMeta.org_name,
                    hcMeta.group_name,
                    hcMeta.program_name,
                    hcMeta.program_priority,
                    hcMeta.deliverable_name,
                    hcMeta.deliverable_priority,
                    hcMeta.cost_centre,
                    hcMeta.updated_by,
                    hcMeta.updated_at,
                ].map((val) => ({ data: { value: val, type: 'String' } }))
                cellMetadata.push({ data: { value: hcMeta.hc_export, type: 'Number' } })
                flattenedRows.push({ cells: cellMetadata })
            })
            exportData = [...exportData, ...flattenedRows]
        }
    })
    // before returning, prepend a row with column definitions
    return [
        {
            cells: hiddenColumnDefs.map((col) => ({
                data: {
                    value:
                        !isEffortEstimate || (isEffortEstimate && col.field !== 'hc_export')
                            ? col.headerName
                            : 'Effort (FTE-Months)',
                    type: 'String',
                },
            })),
        },
        ...exportData,
    ]
}
export const getFlatDataForSheet = (gridApi, isEffortEstimate?) => {
    if (!gridApi) {
        return []
    }
    // todo - refactor so we get empty data and then append row data
    const exportFields = getHiddenExportFields()
    return gridApi?.getSheetDataForExcel({
        skipRowGroups: true,
        skipPinnedBottomRowData: true,
        skipColumnGroupHeaders: true,
        skipColumnHeaders: true,
        shouldRowBeSkipped: () => true,
        prependContent: getFlatDataForRowNode(gridApi, isEffortEstimate ?? false),
        columnKeys: exportFields,
        sheetName: 'Raw Data',
    })
}

export const addExportMetadataForRow = (row, hcItem, orgName, groupName, isEffortEstimate?) => {
    if (!row.export) {
        row.export = []
    }
    row.export.push({
        org_name: orgName,
        group_name: groupName,
        cost_centre: hcItem.headcount_type.toUpperCase(),
        hc_export: isEffortEstimate ? hcItem.fte_month_value : hcItem.headcount_value,
        program_name: row?.program_name,
        program_priority: row?.priority,
        deliverable_name: row?.deliverable_name?.name,
        deliverable_priority: row?.deliverable_priority,
        updated_at: hcItem?.updated_at,
        updated_by: hcItem?.updated_by,
    })
}
const processSingleNumberCondition = (conditionMeta) => {
    const filterType = conditionMeta.type
    const filterValue = conditionMeta.filter
    const getValueFromCondition = () => {
        switch (filterType) {
            case 'lessThan':
                return `< ${filterValue}`
            case 'lessThanOrEqual':
                return `<= ${filterValue}`
            case 'greaterThan':
                return `> ${filterValue}`
            case 'greaterThanOrEqual':
                return `>= ${filterValue}`
            case 'equals':
                return `= ${filterValue}`
            case 'notEqual':
                return `!= ${filterValue}`
            case 'inRange':
                return `Between ${filterValue} and ${conditionMeta.filterTo}`
            case 'blank':
                return 'Blank'
            case 'notBlank':
                return 'Not Blank'
            default:
                return ''
        }
    }
    return { data: { value: getValueFromCondition(), type: 'String' } } as ExcelCell
}

const processFilterValues = (filterValues): ExcelCell[] => {
    switch (filterValues.filterType) {
        case 'set':
            // get list of values
            return filterValues.values.map((val: string) => ({
                data: { value: val, type: 'String' },
            }))
        case 'number':
            // if number - for condition, get type of filter, and value
            // there may be a list of conditions -> process each condition to get type + value
            if (filterValues?.conditions) {
                const conditionList: ExcelCell[] = []
                filterValues.conditions.forEach((condition, idx) => {
                    conditionList.push(processSingleNumberCondition(condition))
                    if (idx < filterValues.conditions.length - 1) {
                        conditionList.push({
                            data: { value: `Operator: ${filterValues?.operator}`, type: 'String' },
                        })
                    }
                })
                return conditionList
            }
            return [processSingleNumberCondition(filterValues)]
        default:
            return []
    }
}
export const getFilterMetadataForExport = (gridApi): ExcelRow[] => {
    const filterModel = gridApi.getFilterModel()
    if (_.isEmpty(filterModel)) {
        return []
    }
    const numFilters = Object.keys(filterModel).length
    const colDefs = gridApi.getColumnDefs()
    const headerRow: ExcelCell[] = []
    const filterValueCells: ExcelCell[][] = []
    Object.keys(filterModel).forEach((keyName, colIdx) => {
        let colDef
        if (MONTHLY_EXPENDITURE_FIELDS.includes(keyName)) {
            const monthlyFieldsParent =
                colDefs.find((col) => col.headerName === 'Monthly Expenditure') ?? {}
            const monthlyFields = monthlyFieldsParent.children ?? []
            colDef = monthlyFields.find((col) => col.field === keyName)
        } else {
            colDef = colDefs.find((col) => col.field === keyName)
        }
        if (!colDef) {
            console.error(`Col def not found for filter export. Key: ${keyName}`)
            return
        }
        headerRow.push({
            data: { value: colDef.headerName, type: 'String' },
            styleId: EXCEL_STYLE_IDS.UNDERLINE_CELL,
        })
        // get list of ExcelCell, string of filter values applied
        const selectedFilters = processFilterValues(filterModel[keyName])
        selectedFilters.forEach((filterCell: ExcelCell, rowIdx) => {
            if (rowIdx > filterValueCells.length - 1) {
                filterValueCells.push(Array(numFilters).fill(DEFAULT_EMPTY_CELL))
            }
            filterValueCells[rowIdx][colIdx] = filterCell
        })
    })
    return [{ cells: headerRow }, ...filterValueCells.map((cellList) => ({ cells: cellList }))]
}

export const getExportPreferencesSheet = (gridApi, customRowData: ExcelRow[]) => {
    if (!gridApi) {
        return []
    }

    return gridApi?.getSheetDataForExcel({
        processHeaderCallback: () => '',
        skipColumnGroupHeaders: true,
        shouldRowBeSkipped: () => true,
        prependContent: [
            ...customRowData,
            { cells: [] },
            {
                cells: [
                    {
                        data: { value: 'Filters Applied', type: 'String' },
                        styleId: EXCEL_STYLE_IDS.BOLD_CELL,
                    },
                ],
            },
            ...getFilterMetadataForExport(gridApi),
        ],
        sheetName: 'Export Preferences',
    })
}
export enum EXCEL_STYLE_IDS {
    BOLD_CELL = 'boldCell',
    UNDERLINE_CELL = 'underlineCell',
}
export const EXCEL_STYLES = [
    { id: EXCEL_STYLE_IDS.BOLD_CELL, font: { bold: true } },
    { id: EXCEL_STYLE_IDS.UNDERLINE_CELL, font: { underline: 'Single' } as ExcelFont },
]
