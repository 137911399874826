import { v4 as uuidv4 } from 'uuid'
import dateFormat from 'dateformat'
import { Tooltip } from '@mui/material'

import { Box } from '@amzn/awsui-components-react'
import { HOLIDAY, EGRET } from '../Constant'
const pako = require('pako')
export const NON_NUMERIC_REGEX = /[$\,-]*/g

export const shortenStringWithEllipsis = (text, textLength) => {
    return text.substring(0, textLength) + '...'
}

export const tooltipWrapper = (tooltipValue, object) => {
    return (
        <Tooltip
            title={
                <Box variant='awsui-key-label' color='inherit'>
                    {tooltipValue}
                </Box>
            }
            placement='bottom'
        >
            {object}
        </Tooltip>
    )
}
export const generateUuid = () => {
    return uuidv4()
}

export const generateShortUuid = () => {
    return require('short-uuid').generate() // 73WakrfVbNJBaAmhQtEeDv
}

export const isDuplicateByPropertyValue = (
    skipIdName: string,
    skipId: string,
    list,
    property: string,
    value,
) => {
    // skip check duplicate by skipIdName and skipId if that item is current editing
    return (
        list.findIndex((item) => {
            if (item[skipIdName] === skipId) {
                return false
            }
            return item[property] === value
        }) !== -1
    )
}

export const convertBoolStringToBool = (original) => {
    return original === 'True'
}

export const convertToLocalTime = (utcDateTime) => {
    return utcDateTime ? dateFormat(new Date(utcDateTime), 'yyyy-mm-dd HH:MM:ss') : ''
}

export const mapToOptions = (optionList) => {
    return optionList.map((option) => ({
        ['label']: option,
        ['value']: option,
    }))
}

export const isValidChanged = (value, selected, prop) => {
    if (!selected) {
        return true
    }
    return selected[prop] !== value
}

export const getGroupOptions = (selectedBusinessEntityId, allUserGroups) => {
    return allUserGroups
        .filter(
            (group) =>
                group.business_entity_id === selectedBusinessEntityId &&
                group.is_egret &&
                group.is_active &&
                !group?.archived,
        )
        .sort((group1, group2) =>
            (group1?.object_name || '').toLowerCase() < (group2?.object_name || '').toLowerCase()
                ? -1
                : 1,
        )
        .map((userGroupObject) => ({
            label: userGroupObject.object_name,
            value: userGroupObject.object_id,
        }))
}

export const getOrgOptions = (allOrgs) => {
    return allOrgs
        .sort((org1, org2) =>
            (org1?.org_name || '').toLowerCase() < (org2?.org_name || '').toLowerCase() ? -1 : 1,
        )
        .map((userOrgObject) => ({
            label: userOrgObject.org_name,
            value: userOrgObject.org_id,
        }))
}

export const styleHeaderWithNoWrap = (headerText) => {
    return <p style={{ whiteSpace: 'nowrap' }}>{headerText}</p>
}

export const styleHeaderConditionally = (attrDef) => {
    const content = attrDef?.headerDisplay ? attrDef.headerDisplay : attrDef.headerName
    return attrDef?.tableVisible || attrDef?.summaryVisible
        ? styleHeaderWithNoWrap(content)
        : content
}

export const getEgretGroups = (allGroups: any[]) => {
    return (allGroups || []).filter((gp) => gp?.is_egret)
}

export const capitalizeFirstLetter = (str: string) => {
    if (!str) {
        return ''
    }

    let result = ''
    str.split('_').forEach((str) => {
        const strLowered: string = str.toLowerCase()
        result += `${strLowered[0].toUpperCase()}${strLowered.slice(1)} `
    })
    return result.trim()
}

const SPECIAL_KEY_NAMES = ['op', 'op1', 'op2', 'stl', 'eoy', 'rl', 'ga', 'ar', 'ct', 'ff', 'fte']
const OVERRIDE_KEY_NAMES = {
    ['headcount_value']: 'fte_estimate',
    ['fte_month_value']: 'fte_month_estimate',
}
export const formatFieldName = (databaseFieldName: string) => {
    if (!databaseFieldName) {
        return ''
    }
    const fieldNameToUse =
        databaseFieldName in OVERRIDE_KEY_NAMES
            ? OVERRIDE_KEY_NAMES[databaseFieldName]
            : databaseFieldName
    const fields = fieldNameToUse.split('_').map((fieldName) => {
        return !SPECIAL_KEY_NAMES.includes(fieldName)
            ? capitalizeFirstLetter(fieldName)
            : fieldName.toUpperCase()
    })
    return fields.join(' ')
}

export const sortStringList = (stringList, overrideKeySort: string[] = []) => {
    return stringList.sort((key1, key2) => {
        if (
            !overrideKeySort.length ||
            (!overrideKeySort.includes(key1) && !overrideKeySort.includes(key2))
        ) {
            return key1 < key2 ? -1 : 1
        }
        const key1Index = overrideKeySort.indexOf(key1)
        const key2Index = overrideKeySort.indexOf(key2)
        if (key1Index === -1 || key2Index === -1) {
            return key1Index !== -1 ? -1 : 1
        }
        return key1Index - key2Index
    })
}

export const getHolidaySession = () => {
    const today = new Date()
    const currentYear = today.getFullYear()
    const octoberFirstString = new Date(`${currentYear}-10-01`)
    const octoberLastString = new Date(`${currentYear}-10-31`)
    const nextJanuaryFifthLastString = new Date(`${currentYear + 1}-01-05`)

    switch (true) {
        case today >= octoberFirstString && today <= octoberLastString:
            return HOLIDAY.HALLOWEEN
        case today > octoberLastString && today <= nextJanuaryFifthLastString:
            return HOLIDAY.CHRISTMAS
        default:
            return EGRET
    }
}

export const decompressResponse = (responseData) => {
    return JSON.parse(
        pako.inflate(new Uint8Array(responseData), {
            to: 'string',
        }),
    )
}

export const formatDbFieldName = (fieldName) => {
    return fieldName
        ?.split('_')
        .map((val) => capitalizeFirstLetter(val))
        .join(' ')
}

export const sortNumericStrings = (input1, input2) => {
    const normalize = (inp) => {
        const parsed = parseFloat(inp.replaceAll(NON_NUMERIC_REGEX, ''))
        return isNaN(parsed) ? 0 : parsed
    }
    return normalize(input1) < normalize(input2) ? -1 : 1
}

export const mergeTwoMaps = (map1, map2) => {
    const newMapEntries: any[] = []
    const addValuesToMap = (currentMap) => {
        currentMap.forEach((val, key, map) => {
            newMapEntries.push([key, val])
        })
    }
    addValuesToMap(map1)
    addValuesToMap(map2)
    return new Map(newMapEntries)
}
