import { useAppContext } from '../../../../context'
import { useEffect, useReducer, useState } from 'react'
import {
    ALERT_TYPES,
    EMPTY_SELECTION,
    MODAL_MODES,
    NOT_APPLICABLE,
    SELECT_MOVE_PROGRAM_OPTION,
} from '../../../Constant'
import {
    Box,
    Button,
    ContentLayout,
    Flashbar,
    SpaceBetween,
    Modal,
    Select,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../../reusable/HeaderTemplate'
import { ALERT_TEMPLATE } from '../../reusable/TextUtil'
import DeleteModal from '../../reusable/DeleteModal'
import DeliverableList from './DeliverableList'
import { generateInitialDeliverableState } from './DeliverableConstants'
import {
    getDeliverableColumnDefinitions,
    getDeliverableTableVisibleColumns,
    getProgramColumnDefinitionsForDeliverables,
} from './DeliverableAttributes'
import CreateDeliverable from './CreateDeliverable'
import { useNavigate, useLocation } from 'react-router-dom'
import useStore from '../../../Store'
import useLocalDeliverableStore from './LocalDeliverableStore'
import {
    getFormattedAttributes,
    getProgramGlobalAttributes,
    getProgramSummaryVisibleColumns,
    PROGRAM_SELECTION_IDS,
    programAttributes,
} from '../ProgramAttributes'
import GenericSummaryTable from '../../reusable/GenericSummaryTable'
import CreateEditModal from '../CreateEditModal'
import {
    emptyFn,
    filterVisibleColsByTerms,
    formatPlanOptions,
    formatRevisionOptions,
    getAllProgramRevisionOptionsForPlan,
    getMetadataForPlan,
    getProgramNameWithoutActiveStatus,
} from '../../reusable/Utils'
import CopyDeliverableWizard from './copy_deliverable/CopyDeliverableWizard'
import { getGroupOptions } from '../../../common/Util'
import { generateInitialState } from '../ProgramConstants'
import { programReducer, REDUCER_ACTIONS } from '../ProgramSharedUtils'
import RevisionAlert, { SOURCE_PAGE } from '../../reusable/RevisionAlert'
import { reducer } from './DelivUtil'
import { HEADER_BG_COLOR } from '../../plan/Constants'
import { DELIVERABLE_PAGE_SUB_DESC } from '../../home/HomePageConstants'

const SetupDeliv = () => {
    const appContext = useAppContext()
    const userProps = appContext.userProps
    const apiClient = appContext.apiClient
    const canAdmin = useStore((state) => state.canAdmin)
    const canEdit = useStore((state) => state.canEditPrograms)
    const canEstimate = useStore((state) => state.canScopeProgramsDeliverables)
    const location = useLocation()
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const paths = location.pathname.split('/')
    const planId = paths[2]
    const revisionId = paths[4]
    const programId = paths[6]
    const programType = urlParams.get('type') || ''
    const isGlobalDeliverableView = programType === 'global'
    const localPrograms = urlParams.get('programs')?.split(',') || []
    const navigate: any = useNavigate()
    const [businessEntityPlans, setBusinessEntityPlans] = useState<any>([])
    const [programNotInPlanAlert, setProgramNotInPlanAlert] = useState<string>('')
    const programMetadata = useLocalDeliverableStore((state) => state.programMetadata)
    const setProgramMetadata = useLocalDeliverableStore((state) => state.setProgramMetadata)

    const [selectedPlan, setSelectedPlan] = useState<any>(EMPTY_SELECTION)
    const [planMetadata, setPlanMetadata] = useState<any>({})
    const [createEditModalVisible, setCreateEditModalVisible] = useState(false)
    const [programModalVisible, setProgramModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [copyModalVisible, setCopyModalVisible] = useState(false)
    const [modalMode, setModalMode] = useState<MODAL_MODES>(MODAL_MODES.NOT_SET)
    const [isDelivLoading, setIsDelivLoading] = useState(true)
    const [deliverables, setDeliverables] = useState<any[]>([])
    const [selectedDelivs, setSelectedDelivs] = useState<any>([])
    const [programName, setProgramName] = useState('')
    const [isDataUnmodified, setIsDataUnmodified] = useState<boolean>(true)
    const [isInputInvalid, setIsInputInvalid] = useState<boolean>(false)
    const selectedRevisionOptions = useLocalDeliverableStore((state) => state.revisionOptions)
    const setSelectedRevisionOptions = useLocalDeliverableStore(
        (state) => state.setSelectedRevisionOptions,
    )
    const [selectedRevision, setSelectedRevision] = useState<any>(EMPTY_SELECTION)
    const [isProgramLoading, setIsProgramLoading] = useState(true)
    const [programColumns, setProgramColumns] = useState<any[]>([])
    const [alertItems, setAlertItems] = useState<any[]>([])
    const [isRevisionLocked, setIsRevisionLocked] = useState(false)
    const initialState = generateInitialDeliverableState()
    const delivListColumnDef = getDeliverableColumnDefinitions()
    const delivVisibleColumns = getDeliverableTableVisibleColumns()
    const [programVisibleColumns, setProgramVisibleColumns] = useState<any[]>([])
    const [copyHcEstimates, setCopyHcEstimates] = useState<any[]>([])
    const [aggregateHcEstimates, setAggregateHcEstimates] = useState<any[]>([])
    const [selectedAggregateHcEstimates, setSelectedAggregateHcEstimates] = useState<any[]>([])
    const [isDeactivateSourceDeliverables, setIsDeactivateSourceDeliverables] =
        useState<boolean>(false)
    const [isDeactivateSourceProgram, setIsDeactivateSourceProgram] = useState<boolean>(false)
    const [spends, setSpends] = useState<any[]>([])
    const [selectedCopyProgramOption, setSelectedCopyProgramOption] = useState<any>(
        SELECT_MOVE_PROGRAM_OPTION,
    )
    const [isCopyHcEstimatesLoading, setIsCopyHcEstimatesLoading] = useState(false)

    const setDeliSummaryColumns = useLocalDeliverableStore(
        (state) => state.setDeliverableSummaryColumns,
    )
    const setDeliVisibleColumns = useLocalDeliverableStore(
        (state) => state.setDeliverableSummaryVisibleColumns,
    )
    const setProgSummaryColumns = useLocalDeliverableStore(
        (state) => state.setProgramSummaryColumns,
    )
    const setProgVisibleColumns = useLocalDeliverableStore(
        (state) => state.setProgramSummaryVisibleColumns,
    )
    const setSelectedProgram = useLocalDeliverableStore((state) => state.setSelectedProgram)
    const setSelectedProgramRevision = useLocalDeliverableStore(
        (state) => state.setSelectedProgramRevision,
    )
    const setHcSelectedDeliverables = useLocalDeliverableStore(
        (state) => state.setHcSelectedDeliverables,
    )
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const userGroups = useStore((state) => state.userGroups)
    const groupOptions = getGroupOptions(selectedBusinessEntity?.business_entity_id, userGroups)

    setDeliSummaryColumns(delivListColumnDef)
    setDeliVisibleColumns(delivVisibleColumns)

    const selectCopyProgramName = getProgramNameWithoutActiveStatus(selectedCopyProgramOption)
    const [state, dispatch] = useReducer(reducer, initialState)
    const [programState, dispatchProgram] = useReducer(
        programReducer,
        generateInitialState(programAttributes),
    )

    const inputChange = (target: string, value: string) => {
        dispatch({ type: REDUCER_ACTIONS.input, target: target, value: value })
    }

    const clearAllInputs = () => {
        dispatch({ type: REDUCER_ACTIONS.initial })
    }

    const loadSelect = (target, itemType: string) => {
        if (itemType === 'program') {
            dispatchProgram({
                type: REDUCER_ACTIONS.load,
                target: target,
            })
            return
        }
        dispatch({
            type: REDUCER_ACTIONS.load,
            target: target,
        })
    }

    const handleAddAlertItem = (content: string, type: ALERT_TYPES) => {
        const id = `${userProps.userAlias}-${Date.now()}`
        const alert = {
            onDismiss: () => {
                setAlertItems((items) => items.filter((item) => item.id !== id))
            },
            dismissible: true,
            content: content,
            type: type,
            id: id,
        }
        setAlertItems([alert, ...alertItems])
    }

    const handleProgramView = (program) => {
        setModalMode(MODAL_MODES.VIEW)
        loadSelect(programMetadata, 'program')
        setProgramModalVisible(true)
    }
    const handleCreate = () => {
        setModalMode(MODAL_MODES.CREATE)
        setCreateEditModalVisible(true)
    }

    const handleEdit = () => {
        setModalMode(MODAL_MODES.EDIT)
        setCreateEditModalVisible(true)
    }

    const handleDelete = () => {
        setDeleteModalVisible(true)
    }

    const handleCopy = () => {
        getHcEstimatesUnderDeliverables()
        setCopyModalVisible(true)
    }

    const getAllSpends = () => {
        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/program/${programId}/discretionary-spends`)
            .then((res) => {
                setSpends(res.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const formateHcEstimates = (hcEstimates) => {
        const aggregatedHcEstimates = {}

        const getDeliverableName = (deliverable_id: string) => {
            const deliverable = selectedDelivs.find(
                (deliv) => deliv.deliverable_id === deliverable_id,
            )
            if (deliverable) {
                return deliverable.deliverable_name
            }

            return NOT_APPLICABLE
        }

        const getGroupName = (group_id: string) => {
            const group = groupOptions.find((group) => group.value === group_id)
            if (group) {
                return group.label
            }

            return NOT_APPLICABLE
        }

        const formatHcEstimateValue = (value) => {
            return Math.round(value * 100) / 100
        }

        const buildAggregateHcEstimate = (deliverable_id, group_id, hcEstimate) => {
            const headcountType = hcEstimate.headcount_type
            return {
                deliverable_id: deliverable_id,
                deliverable_name: getDeliverableName(deliverable_id),
                group_id: group_id,
                group_name: getGroupName(group_id),
                hc_ct: formatHcEstimateValue(
                    headcountType === 'ct' ? hcEstimate.headcount_value : 0,
                ),
                hc_ff: formatHcEstimateValue(
                    headcountType === 'ff' ? hcEstimate.headcount_value : 0,
                ),
                hc_total: formatHcEstimateValue(hcEstimate.headcount_value),
            }
        }

        hcEstimates.forEach((hcEstimate) => {
            const deliverable_id = hcEstimate.deliverable_id
            const group_id = hcEstimate.group_id
            if (deliverable_id in aggregatedHcEstimates) {
                if (group_id in aggregatedHcEstimates[deliverable_id]) {
                    const existingHcEstimate = aggregatedHcEstimates[deliverable_id][group_id]
                    const headcountType = hcEstimate.headcount_type
                    existingHcEstimate.hc_ct = formatHcEstimateValue(
                        +existingHcEstimate.hc_ct +
                            +(headcountType === 'ct' ? hcEstimate.headcount_value : 0),
                    )
                    existingHcEstimate.hc_ff = formatHcEstimateValue(
                        +existingHcEstimate.hc_ff +
                            +(headcountType === 'ff' ? hcEstimate.headcount_value : 0),
                    )
                    existingHcEstimate.hc_total = formatHcEstimateValue(
                        +existingHcEstimate.hc_total + +hcEstimate.headcount_value,
                    )
                } else {
                    aggregatedHcEstimates[deliverable_id][group_id] = buildAggregateHcEstimate(
                        deliverable_id,
                        group_id,
                        hcEstimate,
                    )
                }
            } else {
                aggregatedHcEstimates[deliverable_id] = {}
                aggregatedHcEstimates[deliverable_id][group_id] = buildAggregateHcEstimate(
                    deliverable_id,
                    group_id,
                    hcEstimate,
                )
            }
        })

        let result: any[] = []
        for (const deliverable_id in aggregatedHcEstimates) {
            result = [...result, ...Object.values(aggregatedHcEstimates[deliverable_id])]
        }
        return result
    }

    const getHcEstimatesUnderDeliverables = () => {
        setIsCopyHcEstimatesLoading(true)
        const deliverable_ids = selectedDelivs.map((deliv) => deliv['deliverable_id']).join(',')
        apiClient
            .get(
                `/program/${programId}/deliverables/headcount-estimates?plan_id=${planId}&revision_id=${revisionId}&deliverable_ids=${deliverable_ids}`,
            )
            .then((res) => {
                const hcEstimates = res.data
                setCopyHcEstimates(hcEstimates)
                setAggregateHcEstimates(formateHcEstimates(hcEstimates))
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => setIsCopyHcEstimatesLoading(false))
    }

    const getLocalProgramMetadata = (currentRevisionId) => {
        apiClient
            .get(`/plan/${planId}/revision/${currentRevisionId}/program/${programId}`)
            .then((res) => {
                let program = res.data
                program = {
                    ...program,
                    stl_alias: program['stl_alias'] ? [program['stl_alias']] : [],
                    registered_users: program['registered_users']
                        ? program['registered_users']
                        : [],
                }
                setProgramMetadata(program)
                setProgramName(program.program_name)
            })
            .catch((error) => {
                console.error(error)
                setSelectedProgram(undefined)
                setIsProgramLoading(false)
            })
    }

    const getParentProgramMetadata = () => {
        apiClient
            .get(`/parent-program/${programId}`)
            .then((res) => {
                let program = res.data
                program = {
                    ...program,
                    stl_alias: program['stl_alias'] ? [program['stl_alias']] : [],
                    registered_users: program['registered_users']
                        ? program['registered_users']
                        : [],
                    ...generateInitialState(getProgramGlobalAttributes()),
                    kingpin_goals: [],
                    important_links: [],
                }
                setProgramMetadata(program)
                setProgramName(program.program_name)
                setSelectedProgram(program)
                setProgramNotInPlanAlert(
                    `${program.program_name} has not been added to ${planMetadata?.plan_type} ${planMetadata?.year}.`,
                )
            })
            .catch((error) => {
                console.error(error)
                setSelectedProgram(undefined)
                setIsProgramLoading(false)
                setProgramNotInPlanAlert('')
            })
    }

    const getBusinessEntityPlans = () => {
        apiClient
            .get(`/plan/business-entity/${selectedBusinessEntity?.business_entity_id}?year=`)
            .then((res) => {
                const allPlanOptions = res.data.map((plan) => formatPlanOptions(plan))
                setBusinessEntityPlans(allPlanOptions)
                const filteredPlan = allPlanOptions.find((plan) => plan.value.plan_id === planId)
                setSelectedPlan(filteredPlan ?? EMPTY_SELECTION)
                setSelectedRevision(
                    filteredPlan.value?.revisions?.length
                        ? formatRevisionOptions(filteredPlan.value.revisions[0])
                        : EMPTY_SELECTION,
                )
            })
            .catch((err) => {
                console.error(err)
                setBusinessEntityPlans([])
                setSelectedPlan(EMPTY_SELECTION)
            })
    }

    const getGlobalProgramMetadata = () => {
        const planType = planMetadata?.plan_type
        const planYear = planMetadata?.year
        apiClient
            .get(
                `/parent-program/${programId}/global-attributes?plan_type=${planType}&plan_year=${planYear}`,
            )
            .then((res) => {
                let program = res.data
                program = {
                    ...program,
                    stl_alias: program['stl_alias'] ? [program['stl_alias']] : [],
                    registered_users: program['registered_users']
                        ? program['registered_users']
                        : [],
                }
                setProgramMetadata(program)
                setProgramName(program.program_name)
                setSelectedProgram(program)
                setProgramNotInPlanAlert('')
            })
            .catch((error) => {
                console.error(error)
                if (error?.response?.status === 400) {
                    getParentProgramMetadata()
                    return
                }
                setSelectedProgram(undefined)
                setIsProgramLoading(false)
            })
    }

    const getProgramMetadata = () => {
        setIsProgramLoading(true)
        if (isGlobalDeliverableView) {
            getGlobalProgramMetadata()
        } else {
            getLocalProgramMetadata(revisionId)
        }
    }

    const loadPage = () => {
        getProgramMetadata()
        if (!isGlobalDeliverableView) {
            getAllSpends()
            getAllRevisionOptions()
        } else {
            setSelectedRevision(formatRevisionOptions(planMetadata.revisions[0]))
        }
    }

    const getProgramColumns = () => {
        apiClient
            .get(`/settings?keys=${PROGRAM_SELECTION_IDS.join(',')}`)
            .then((res) => {
                const selections = res.data
                const columns = getProgramColumnDefinitionsForDeliverables(
                    handleProgramView,
                    programMetadata,
                    isGlobalDeliverableView,
                    getFormattedAttributes(selections, programAttributes),
                )
                setProgramColumns(columns)
                setProgSummaryColumns(columns)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => setIsProgramLoading(false))
    }

    const getAllRevisionOptions = () => {
        getAllProgramRevisionOptionsForPlan(
            apiClient,
            programId,
            revisionId,
            planId,
            onSelectedRevisionChange,
            setSelectedRevisionOptions,
            handleAddAlertItem,
        )
        getAllDeliverablesByProgram()
    }

    const onSelectedRevisionChange = (selectedOption) => {
        setSelectedRevision(selectedOption)
        setSelectedProgramRevision(selectedOption)
    }

    const getAllDeliverablesByProgram = () => {
        const currentIsProgram = programMetadata.is_true_program
            ? programMetadata.is_true_program
            : false

        getAllDeliverables(planId, revisionId, currentIsProgram)
    }

    const getAllDeliverables = (currentPlanId, currentRevisionId, currentIsProgram) => {
        apiClient
            .get(
                `/plan/${currentPlanId}/revision/${currentRevisionId}/program/${programId}/deliverables?is_true_program=${currentIsProgram}&is_global_program=${
                    isGlobalDeliverableView ? 'true' : 'false'
                }`,
            )
            .then((res) => {
                setDeliverables(res.data)
                setIsDelivLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setDeliverables([])
                setIsDelivLoading(false)
            })
    }

    const deleteDeliverable = () => {
        setDeleteModalVisible(false)
        setIsDelivLoading(true)
        if (!isRevisionLocked) {
            apiClient
                .delete(
                    `/plan/${planId}/revision/${revisionId}/program/${programId}/deliverable/${selectedDelivs[0].deliverable_id}`,
                )
                .then(() => {
                    getAllDeliverablesByProgram()
                    handleAddAlertItem(
                        `Successfully deleted deliverable ${selectedDelivs[0].deliverable_name}.`,
                        ALERT_TYPES.SUCCESS,
                    )
                    setSelectedDelivs([])
                    setHcSelectedDeliverables([])
                    setIsDelivLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                    handleAddAlertItem(
                        `Failed to delete deliverable ${selectedDelivs[0].deliverable_name}: ${error.response.data}`,
                        ALERT_TYPES.ERROR,
                    )
                    setSelectedDelivs([])
                    setHcSelectedDeliverables([])
                    setIsDelivLoading(false)
                })
        } else {
            handleAddAlertItem('Cannot delete deliverables from locked revision', ALERT_TYPES.ERROR)
            setSelectedDelivs([])
            setHcSelectedDeliverables([])
            setIsDelivLoading(false)
        }
    }

    useEffect(() => {
        if (!isGlobalDeliverableView) {
            return
        }
        getBusinessEntityPlans()
    }, [selectedBusinessEntity])

    useEffect(() => {
        if (!programMetadata.program_id && !programMetadata.parent_program_id) {
            setProgramVisibleColumns([])
            setProgVisibleColumns([])
            return
        }
        getProgramColumns()
        const visibleColumns = getProgramSummaryVisibleColumns()
        setProgramVisibleColumns(visibleColumns)
        setProgVisibleColumns(visibleColumns)
    }, [programMetadata])

    useEffect(() => {
        getMetadataForPlan(apiClient, planId, setPlanMetadata, setSelectedPlan)
    }, [planId])

    useEffect(() => {
        if (!planMetadata?.plan_id) {
            return
        }
        loadPage()
    }, [planMetadata])

    useEffect(() => {
        getAllDeliverablesByProgram()
    }, [selectedRevision, isRevisionLocked])

    useEffect(() => {
        if (!planMetadata?.plan_id || !selectedRevision.value) {
            return
        }

        getProgramColumns()
    }, [planMetadata, selectedRevision])

    return (
        <ContentLayout
            defaultPadding
            headerBackgroundStyle={HEADER_BG_COLOR}
            headerVariant='high-contrast'
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            subtitle={DELIVERABLE_PAGE_SUB_DESC}
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: 'Programs',
                                    href: isGlobalDeliverableView
                                        ? '/programs'
                                        : `/plan/${planId}/revision/${revisionId}`,
                                },
                                {
                                    text: `${
                                        programMetadata.program_id
                                            ? programMetadata.program_name
                                            : ''
                                    } Deliverables`,
                                    href: '',
                                },
                            ]}
                        />
                        {Boolean(alertItems.length) && (
                            <Flashbar items={alertItems} stackItems={true} />
                        )}

                        {isGlobalDeliverableView ? (
                            <ALERT_TEMPLATE
                                header={'View Only Mode'}
                                text={
                                    'View Only mode for Global Program. To submit HC Estimates and Spend Estimates, go to local programs page in your business entity. '
                                }
                                type={ALERT_TYPES.INFO}
                                dismissible={false}
                            />
                        ) : (
                            <ALERT_TEMPLATE
                                header={'Deletion Limit'}
                                text={'Only one deliverable can be deleted at a time. '}
                                type={ALERT_TYPES.INFO}
                                dismissible={false}
                            />
                        )}
                        {programNotInPlanAlert && (
                            <ALERT_TEMPLATE
                                header={'No Global Metadata'}
                                text={programNotInPlanAlert}
                                type={ALERT_TYPES.WARNING}
                                dismissible={false}
                            />
                        )}
                        {!isGlobalDeliverableView && (
                            <RevisionAlert
                                planId={planId}
                                selectedRevisionId={revisionId}
                                sourcePage={SOURCE_PAGE.DELIVERABLE}
                                setIsLocked={setIsRevisionLocked}
                                apiClient={apiClient}
                            />
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <CreateDeliverable
                isStlNotAdmin={canEdit && !canAdmin}
                state={state}
                planID={planId}
                programID={programId}
                isProgram={programMetadata.is_true_program}
                revisionID={selectedRevision.value || ''}
                inputChange={inputChange}
                onAlertItemAdd={handleAddAlertItem}
                onDismiss={() => setCreateEditModalVisible(false)}
                clearAllInputs={clearAllInputs}
                onIsInputInvalidChanged={setIsInputInvalid}
                onIsDataUnmodifiedChanged={setIsDataUnmodified}
                isAddingDeliverable={false}
                onIsLoadingChange={setIsDelivLoading}
                modalMode={modalMode}
                selectedDeliverables={selectedDelivs}
                onSelectedDeliverablesChange={setSelectedDelivs}
                revisionLocked={isRevisionLocked}
                visible={createEditModalVisible}
                isDataUnmodified={isDataUnmodified}
                isInputInvalid={isInputInvalid}
                reloadDeliverables={getAllDeliverablesByProgram}
                programName={programName}
                deliverables={deliverables}
            />
            <CreateEditModal
                isStlNotAdmin={canEdit && !canAdmin}
                selectedRevision={selectedRevision}
                state={{
                    ...programState,
                    ['kingpin_goals']: programMetadata?.kingpin_goals || [],
                    ['important_links']: programMetadata?.important_links || [],
                }}
                selectedPlan={selectedPlan}
                selectedPlanOptions={businessEntityPlans.map((plan: any) =>
                    formatPlanOptions(plan),
                )}
                inputChange={inputChange}
                clearAllInputs={clearAllInputs}
                isDataUnmodified={true}
                isInputInvalid={false}
                onIsInputInvalidChanged={emptyFn}
                onIsDataUnmodifiedChanged={emptyFn}
                isAddingProgram={false}
                onAlertItemAdd={handleAddAlertItem}
                onDismiss={() => setProgramModalVisible(false)}
                visible={programModalVisible}
                onIsLoadingChange={false}
                isModalExpand={true}
                onIsModalExpandChange={emptyFn}
                modalMode={MODAL_MODES.VIEW}
                programs={programMetadata}
                selectedPrograms={programMetadata}
                onSelectedProgramsChange={setProgramMetadata}
                selectedProgramBusinessEntity={false}
                getLocalPrograms={false}
                getParentPrograms={false}
                hideLocalAttributes={isGlobalDeliverableView}
                initialKingpinGoals={
                    programMetadata?.kingpin_goals?.length ? programMetadata.kingpin_goals : []
                }
                initialImportantLinks={
                    programMetadata?.important_links?.length ? programMetadata.important_links : []
                }
                onSelectPlanChange={emptyFn}
                selectedRevisionOptions={[]}
                onSelectRevisionChange={emptyFn}
                globalAttributesError={''}
                setGlobalAttributesError={emptyFn}
                allParentProgramPlans={[]}
                allParentProgramGlobalAttributes={[]}
                loadSelectProgram={emptyFn}
                refreshPage={emptyFn}
                fetchGlobalAttributesForPlan={emptyFn}
            />
            <DeleteModal
                title='Deliverable'
                visible={deleteModalVisible}
                onDismiss={() => setDeleteModalVisible(false)}
                onDelete={deleteDeliverable}
            />
            <Modal
                onDismiss={() => {
                        setCopyModalVisible(false)
                        setSelectedCopyProgramOption(SELECT_MOVE_PROGRAM_OPTION)
                    }
                }
                visible={copyModalVisible}
                size={'max'}
                header={`Copy Deliverable(s) - ${programName} ${
                    selectedCopyProgramOption.value === SELECT_MOVE_PROGRAM_OPTION.value
                        ? ''
                        : `to ${selectCopyProgramName}`
                }`}
            >
                <CopyDeliverableWizard
                    key={
                        selectedDelivs.length > 0
                            ? selectedDelivs.map((deliv) => deliv.deliverable_id).join(',')
                            : 1
                    }
                    businessEntity={selectedBusinessEntity}
                    program={programMetadata}
                    selectedDeliverables={selectedDelivs}
                    onSelectedDeliverablesChange={setSelectedDelivs}
                    copyHcEstimates={copyHcEstimates}
                    aggregateHcEstimates={aggregateHcEstimates}
                    onAggregateHcEstimatesChange={setAggregateHcEstimates}
                    selectedAggregateHcEstimates={selectedAggregateHcEstimates}
                    onSelectedAggregateHcEstimatesChange={setSelectedAggregateHcEstimates}
                    onCancel={() => setCopyModalVisible(false)}
                    isDeactivateSourceDeliverables={isDeactivateSourceDeliverables}
                    onIsDeactivateSourceDeliverablesChange={setIsDeactivateSourceDeliverables}
                    isDeactivateSourceProgram={isDeactivateSourceProgram}
                    onIsDeactivateSourceProgramChange={setIsDeactivateSourceProgram}
                    handleAddAlertItem={handleAddAlertItem}
                    spends={spends}
                    groupOptions={groupOptions}
                    selectedCopyProgramOption={selectedCopyProgramOption}
                    onSelectedCopyProgramOptionChange={setSelectedCopyProgramOption}
                    selectCopyProgramName={selectCopyProgramName}
                    refreshList={getAllDeliverablesByProgram}
                    isCopyHcEstimatesLoading={isCopyHcEstimatesLoading}
                />
            </Modal>
            <Box margin={{ left: 's', right: 's' }}>
                <SpaceBetween size={'xxl'} direction={'vertical'}>
                    <GenericSummaryTable
                        wrapLines={false}
                        itemsToShow={programMetadata ? [programMetadata] : []}
                        columns={programColumns}
                        visibleColumns={filterVisibleColsByTerms(programVisibleColumns, [
                            'create',
                            'update',
                            'program_name',
                        ])}
                        defaultNameField={'Program'}
                        nameField={'program_name'}
                        isLoading={isProgramLoading}
                        objectType={'Program'}
                        includePagination={false}
                        actions={[
                            ...(!isGlobalDeliverableView
                                ? [
                                      <Button
                                          key={'spend-estimate-btn'}
                                          variant='primary'
                                          onClick={() => {
                                              navigate(
                                                  `/plan/${planId}/revision/${selectedRevision.value || ''}/program/${programId}/discretionary-spend`,
                                              )
                                          }}
                                          disabled={isProgramLoading || !userGroups.length}
                                      >
                                          Spend Estimate
                                      </Button>,
                                  ]
                                : [
                                      <SpaceBetween
                                          size={'xs'}
                                          direction={'horizontal'}
                                          key={'select-plan-container'}
                                      >
                                          <Select
                                              selectedOption={selectedPlan}
                                              options={businessEntityPlans}
                                              disabled={!businessEntityPlans?.length}
                                              onChange={({ detail }) => {
                                                  const newPlan: any = detail.selectedOption.value
                                                  const latestRev =
                                                      newPlan['revisions'][0]?.revision_id
                                                  navigate(
                                                      `/plan/${newPlan.plan_id}/revision/${latestRev}/program/${programId}?type=${programType}`,
                                                  )
                                              }}
                                          />
                                          <Select
                                              selectedOption={selectedRevision}
                                              options={[]}
                                              disabled={true}
                                          />
                                      </SpaceBetween>,
                                  ]),
                        ]}
                    />
                    <DeliverableList
                        programType={programType}
                        localPrograms={localPrograms}
                        programMetadata={programMetadata}
                        canEdit={canEdit}
                        localSelectedPlan={selectedPlan}
                        canEstimate={canEstimate}
                        programName={programName}
                        deliverables={deliverables}
                        isLoading={isDelivLoading}
                        columns={delivListColumnDef}
                        visibleColumns={delivVisibleColumns}
                        onCreate={handleCreate}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onCopy={handleCopy}
                        loadSelect={loadSelect}
                        selectedDelivs={selectedDelivs}
                        onSelectedDelivsChange={setSelectedDelivs}
                        selectedRevision={selectedRevision}
                        onSelectedRevisionChange={onSelectedRevisionChange}
                        selectedRevisionOptions={selectedRevisionOptions}
                        currentPath={location.pathname}
                        isRevisionLocked={isRevisionLocked}
                    />
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default SetupDeliv
