import { useCallback, useEffect, useReducer, useState } from 'react'
import { useAppContext } from '../../../context'
import useStore from '../../Store'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppLayout, Box, ContentLayout, Flashbar, SpaceBetween } from '@amzn/awsui-components-react'
import {
    ALERT_TYPES,
    EMPTY_PLAN_SELECTION,
    EMPTY_REVISION_SELECTION,
    EMPTY_SELECTION,
    GLOBAL_BUSINESS_ENTITY,
    MODAL_MODES,
    SELECT_BUSINESS_ENTITY,
} from '../../Constant'
import HeaderTemplate from '../reusable/HeaderTemplate'
import DeleteModal from '../reusable/DeleteModal'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import ProgramTable from './ProgramTable'
import CreateEditModal from './CreateEditModal'
import { generateInitialState } from './ProgramConstants'
import {
    getFormattedAttributes,
    getGlobalProgramColumnDefinitions,
    getProgramColumnDefinitions,
    getProgramTableExportGlobalVisibleColumns,
    getProgramTableExportLocalVisibleColumns,
    getProgramTableGlobalVisibleColumns,
    getProgramTableLocalVisibleColumns,
    PROGRAM_SELECTION_IDS,
    programAttributes,
} from './ProgramAttributes'
import {
    formatPlanMetadataFromPath,
    formatSelectedRevisionFromPath,
    loadSelectedState,
} from './ProgramSharedUtils'
import { formatPlanOptions, formatRevisionOptions, getItemsApi } from '../reusable/Utils'
import RevisionAlert, { SOURCE_PAGE } from '../reusable/RevisionAlert'
import { convertBoolStringToBool, decompressResponse } from '../../common/Util'
import {
    COMMENT_PANEL_MODE,
    DEFAULT_SELECTED_DELIVERABLE,
    DEFAULT_SELECTED_PROGRAM,
    DEFAULT_SELECTED_ROLE,
    FILTER_PAYLOAD_TYPES,
    MUIAutocompleteOption,
    CommentFilter,
} from '../comment/Constant'
import CommentPanel from '../comment/CommentPanel'
import { HEADER_BG_COLOR } from '../plan/Constants'
import { PROGRAM_PAGE_SUB_DESC } from '../home/HomePageConstants'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import {
    formatCommentPanelSearchOptions,
    generateFilterPayload,
    generateInitialCommentFilters,
    getParentProgramGlobalAttributes,
    getUserRoleSelections,
} from '../comment/CommentUtil'
import _ from 'lodash'

const ProgramPage = () => {
    const appContext = useAppContext()
    const userProps = appContext.userProps
    const apiClient = appContext.apiClient

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const navigate = useNavigate()
    const location: any = useLocation()

    const [deliverablesList, setDeliverablesList] = useState({})
    const [programs, setPrograms] = useState<any[]>([])
    const [selectedPrograms, setSelectedPrograms] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isAddingProgram, setIsAddingProgram] = useState<boolean>(false)
    const [isModalExpand, setIsModalExpand] = useState<boolean>(false)
    const [createEditModalVisible, setCreateEditModalVisible] = useState<boolean>(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
    const [modalMode, setModalMode] = useState<MODAL_MODES>(MODAL_MODES.NOT_SET)
    const [alertItems, setAlertItems] = useState<any[]>([])
    const [isDataUnmodified, setIsDataUnmodified] = useState<boolean>(true)
    const [isInputInvalid, setIsInputInvalid] = useState<boolean>(false)
    const [initialKingpinGoals, setInitialKingpinGoals] = useState<any[]>([])
    const [initialImportantLinks, setInitialImportantLinks] = useState<any[]>([])
    const isAllProgramsPreSelected = location?.state?.isAllProgramsSelected
    const [selectedProgramBusinessEntity, setSelectedProgramBusinessEntity] =
        useState<SelectProps.Option>(
            !isAllProgramsPreSelected
                ? EMPTY_SELECTION
                : { value: GLOBAL_BUSINESS_ENTITY, label: 'All Programs' },
        )

    const [businessEntityPlans, setBusinessEntityPlans] = useState<any>([])
    const [revisionMap, setRevisionMap] = useState<any>(new Map([]))
    const [allOrgs, setAllOrgs] = useState<any[]>([])
    const [allGroups, setAllGroups] = useState<any[]>([])

    const [programBusinessEntityOptions, setProgramBusinessEntityOptions] = useState<
        SelectProps.Option[]
    >([
        {
            label: 'All Programs',
            value: GLOBAL_BUSINESS_ENTITY,
        },
        ...(selectedBusinessEntity.business_entity_id !== SELECT_BUSINESS_ENTITY
            ? [
                  {
                      label: selectedBusinessEntity.business_entity_name,
                      value: selectedBusinessEntity.business_entity_id,
                  },
              ]
            : []),
    ])

    const paths = location.pathname.split('/')
    const [selectedRevisionOptions, setSelectedRevisionOptions] = useState<SelectProps.Option[]>([
        EMPTY_REVISION_SELECTION,
    ])
    const [selectedRevision, setSelectedRevision] = useState<SelectProps.Option>(
        formatSelectedRevisionFromPath(location, paths),
    )
    const initialPlan = {
        ...formatPlanMetadataFromPath(location, paths),
        business_entity_id: selectedBusinessEntity.business_entity_id,
    }

    const [planMetadata, setPlanMetadata] = useState<any>(initialPlan)
    const [isRevisionLocked, setIsRevisionLocked] = useState(false)

    const canAdmin = useStore((state) => state.canAdmin)
    const canEditPrograms = useStore((state) => state.canEditPrograms)

    const isStlNotAdmin = !canAdmin && canEditPrograms
    const [globalAttributesError, setGlobalAttributesError] = useState('')
    const [isLoadingParentProgramPlans, setIsLoadingParentProgramPlans] = useState(false)
    const [isLoadingParentProgramGlobalAttrs, setIsLoadingParentProgramGlobalAttrs] =
        useState(false)
    const [parentProgramAllGlobalAttributes, setParentProgramAllGlobalAttributes] = useState<any>(
        [],
    )
    const [parentProgramAllPlans, setParentProgramAllPlans] = useState<string[]>([])
    const [localColumnDefinitions, setLocalColumnDefinitions] = useState<any[]>(
        getProgramColumnDefinitions(navigate, planMetadata, selectedRevision, programAttributes),
    )
    const localVisibleColumns = getProgramTableLocalVisibleColumns()
    const globalVisibleColumns = getProgramTableGlobalVisibleColumns()
    const [globalColumnDefinitions, setGlobalColumnDefinitions] = useState(
        getGlobalProgramColumnDefinitions(navigate, planMetadata, selectedRevision),
    )
    const [selectedPlan, setSelectedPlan] = useState(formatPlanOptions(planMetadata))

    // comment side panel state
    const userRolesMetadata = useStore((state) => state.userRolesMetadata)
    const userRoleSelections = getUserRoleSelections(userRolesMetadata, selectedPlan?.value)
    const [isSidePanelVisible, setIsSidePanelVisible] = useState<boolean>(false)
    const [commentMode, setCommentMode] = useState<COMMENT_PANEL_MODE>(COMMENT_PANEL_MODE.DISABLED)
    const [selectedRole, setSelectedRole] = useState<SelectProps.Option>(DEFAULT_SELECTED_ROLE)
    const [selectedCommentDeliverable, setSelectedCommentDeliverable] =
        useState<SelectProps.Option>(DEFAULT_SELECTED_DELIVERABLE)
    const [allLocalProgramMap, setAllLocalProgramMap] = useState<any>({})

    const [allGlobalAttributesForPlan, setAllGlobalAttributesForPlan] = useState<any[]>([])
    const [commentDeliverableOptions, setCommentDeliverableOptions] = useState<
        SelectProps.Option[]
    >([])
    const [selectedFilters, setSelectedFilters] = useState<CommentFilter[]>(
        generateInitialCommentFilters(userRoleSelections, selectedPlan?.value),
    )
    const [filterOptions, setFilterOptions] = useState<CommentFilter[]>([])
    const [programNameMap, setProgramNameMap] = useState<any>({})

    useEffect(() => {
        generateCommentFilterOptions()
        if (_.isEmpty(selectedPrograms) || _.isEmpty(deliverablesList)) {
            setCommentDeliverableOptions([])
            return
        }
        const selectedProgramName = selectedPrograms[0]?.program_name
        const programDeliverables = deliverablesList[selectedProgramName] ?? []
        setSelectedCommentDeliverable(DEFAULT_SELECTED_DELIVERABLE)
        setCommentDeliverableOptions(
            programDeliverables.map((deli) => ({
                value: deli.deliverable_id,
                label: deli.deliverable_name,
            })),
        )
    }, [deliverablesList, selectedPrograms])

    const actions = {
        input: 'INPUT',
        initial: 'INITIAL',
        load: 'LOAD',
    }
    const initialState = generateInitialState(programAttributes)

    const getDeliverablesUnderProgram = () => {
        if (!selectedRevision.value || !planMetadata.plan_id) {
            return
        }
        apiClient
            .get(`/plan/${planMetadata?.plan_id}/revision/${selectedRevision.value}/deliverables`)
            .then((res) => {
                setDeliverablesList(res.data)
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const loadSelectedItemState = (state, selectedItem) => {
        const result = loadSelectedState(
            state,
            selectedItem,
            isAddingProgram || modalMode === MODAL_MODES.VIEW || isStlNotAdmin,
            'program',
        )
        setInitialImportantLinks(
            selectedItem?.important_links && selectedItem.important_links.length
                ? [...selectedItem.important_links]
                : [],
        )
        setInitialKingpinGoals(
            selectedItem.kingpin_goals && selectedItem.kingpin_goals.length
                ? [...selectedItem.kingpin_goals]
                : [],
        )
        return result
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case actions.input:
                return {
                    ...state,
                    [action.target]: action.value,
                }
            case actions.initial:
                return initialState
            case actions.load:
                return loadSelectedItemState(state, action.target)
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const inputChange = (target: string, value: string) => {
        dispatch({ type: actions.input, target: target, value: value })
    }

    const clearAllInputs = () => {
        dispatch({ type: actions.initial })
    }

    const loadSelectProgram = (target, isAddingProgram: boolean) => {
        dispatch({
            type: actions.load,
            target: target,
            isAddingProgram: isAddingProgram,
        })
    }

    const handleAddAlertItem = (content: string, type: ALERT_TYPES) => {
        const id = `${userProps.userAlias}-${Date.now()}`
        const alert = {
            onDismiss: () => {
                setAlertItems((items) => items.filter((item) => item.id !== id))
            },
            dismissible: true,
            content: content,
            type: type,
            id: id,
        }
        setAlertItems([alert, ...alertItems])
    }

    const handleClickCreate = () => {
        setModalMode(MODAL_MODES.CREATE)
        setCreateEditModalVisible(true)
    }

    const fetchGlobalAttributesForParentProgramAndPlan = () => {
        const parentProgramId = selectedPrograms[0].parent_program_id
        const planType = planMetadata.plan_type
        const planYear = planMetadata.year
        apiClient
            .get(
                `/parent-program/${parentProgramId}/global-attributes?plan_type=${planType}&plan_year=${planYear}`,
            )
            .then((res) => {
                setGlobalAttributesError('')
                const program = res.data
                const programWithGlobalAttrs = {
                    ...program,
                    stl_alias: program['stl_alias'] ? [program['stl_alias']] : [],
                    registered_users: program['registered_users']
                        ? program['registered_users']
                        : [],
                }
                loadSelectProgram(programWithGlobalAttrs, isAddingProgram)
                setIsLoadingParentProgramGlobalAttrs(false)
            })
            .catch((err) => {
                setGlobalAttributesError(err.response.data)
                fetchAllGlobalAttributesForParentProgram()
                console.error(err.response.data)
                setIsLoadingParentProgramGlobalAttrs(false)
                loadSelectProgram(selectedPrograms[0], isAddingProgram)
            })
    }

    const getBusinessEntityPlans = () => {
        apiClient
            .get(`/plan/business-entity/${selectedBusinessEntity?.business_entity_id}?year=`)
            .then((res) => {
                setBusinessEntityPlans(res.data)
                if (
                    planMetadata?.plan_id &&
                    planMetadata?.business_entity_id === selectedBusinessEntity?.business_entity_id
                ) {
                    const searchedPlan = res.data.find(
                        (plans) => plans.plan_id === planMetadata.plan_id,
                    )
                    setPlanMetadata(searchedPlan)
                    setSelectedPlan(
                        searchedPlan ? formatPlanOptions(searchedPlan) : EMPTY_PLAN_SELECTION,
                    )
                } else {
                    const newPlan = res?.data?.length
                        ? res.data
                              .sort((a, b) => a.created_at.localeCompare(b.created_at))
                              .reverse()[0]
                        : {}
                    setPlanMetadata(newPlan)
                    setSelectedPlan(
                        newPlan?.plan_id ? formatPlanOptions(newPlan) : EMPTY_PLAN_SELECTION,
                    )
                }
            })
            .catch((err) => {
                console.error(err)
                setBusinessEntityPlans([])
            })
    }

    const fetchAllGlobalAttributesForParentProgram = () => {
        const parentProgramId = selectedPrograms[0].parent_program_id
        setIsLoadingParentProgramPlans(true)
        apiClient
            .get(`/parent-program/${parentProgramId}/plans`)
            .then((res) => {
                const plansAndAttrs = res.data
                setParentProgramAllPlans(plansAndAttrs[0])
                const globalAttrs = plansAndAttrs[1].map((globalAttr) => ({
                    ...globalAttr,
                    stl_alias: globalAttr['stl_alias'] ? [globalAttr['stl_alias']] : [],
                    registered_users: globalAttr['registered_users'] || [],
                }))
                setParentProgramAllGlobalAttributes(globalAttrs)
                setIsLoadingParentProgramPlans(false)
            })
            .catch((err) => {
                console.error(err)
                setParentProgramAllPlans([])
                setParentProgramAllGlobalAttributes([])
                setIsLoadingParentProgramPlans(false)
            })
    }
    const handleClickEdit = () => {
        setModalMode(MODAL_MODES.EDIT)
        loadSelectProgram(selectedPrograms[0], false)
        setCreateEditModalVisible(true)
    }

    const handleClickView = (selectedPrograms) => {
        setModalMode(MODAL_MODES.VIEW)
        loadSelectProgram(selectedPrograms[0], false)
        setCreateEditModalVisible(true)
    }

    const handleClickDelete = () => {
        setDeleteModalVisible(true)
    }

    const handleDeleteSubmit = () => {
        const isAllProgramsMode = selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
        const selectedProgram = selectedPrograms[0]
        const planId = planMetadata.plan_id
        const revisionId = selectedRevision.value

        setDeleteModalVisible(false)
        setIsLoading(true)
        apiClient
            .delete(
                isAllProgramsMode
                    ? `/parent-program/${selectedProgram.parent_program_id}`
                    : `/plan/${planId}/revision/${revisionId}/program/${selectedProgram.program_id}`,
            )
            .then(() => {
                isAllProgramsMode ? getParentPrograms() : getLocalPrograms(planId, revisionId!)
                handleAddAlertItem(
                    `Successfully ${isAllProgramsMode ? 'delete' : 'remove'} program ${
                        selectedProgram.program_name
                    }.`,
                    ALERT_TYPES.SUCCESS,
                )
                setSelectedPrograms([])
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(
                    `Failed to ${isAllProgramsMode ? 'delete' : 'remove'} program ${
                        selectedProgram.program_name
                    }: ${error.response.data}`,
                    ALERT_TYPES.ERROR,
                )
                setIsLoading(false)
            })
    }

    const getLocalPrograms = (planId: string, revisionId: string) => {
        setIsLoading(true)
        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/programs`)
            .then((res) => {
                const programs = res.data
                const programNameMap = {}
                const programIdMap = {}
                const newPrograms = programs.map((prog) => ({
                    ...prog,
                    stl_alias: prog['stl_alias'] ? [prog['stl_alias']] : [],
                    registered_users: prog['registered_users'] ? prog['registered_users'] : [],
                    ['is_op_goal']: convertBoolStringToBool(prog.is_op_goal),
                }))
                setPrograms(newPrograms)

                programs.forEach((program) => {
                    programNameMap[program.program_name] = program
                    programIdMap[program.parent_program_id] = program
                })
                setProgramNameMap(programNameMap)
                setAllLocalProgramMap(programIdMap)
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(
                    `Failed to load programs: ${error.response.data}`,
                    ALERT_TYPES.ERROR,
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getParentPrograms = () => {
        setIsLoading(true)
        apiClient
            .get(`/parent-programs`)
            .then((res) => {
                const newPrograms = res.data.map((prog) => ({
                    ...prog,
                    stl_alias: prog['stl_alias'] ? [prog['stl_alias']] : [],
                    registered_users: prog['registered_users'] ? prog['registered_users'] : [],
                }))
                setPrograms(newPrograms)
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(
                    `Failed to load programs: ${error.response.data}`,
                    ALERT_TYPES.ERROR,
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const loadPage = () => {
        const revisionObjectList: any[] = []
        // loadPage is only ever called when selectedPlan is set
        const formattedRevisionOptions = planMetadata?.revisions
            ? planMetadata.revisions.map((rev: any) => {
                  revisionObjectList.push([rev.revision_id, rev])
                  return formatRevisionOptions(rev)
              })
            : []
        setRevisionMap(new Map(revisionObjectList))
        if (Object.keys(formattedRevisionOptions).length === 0) {
            setSelectedRevision(EMPTY_REVISION_SELECTION)
            setSelectedRevisionOptions([])
            setPrograms([])
            setIsLoading(false)
            return
        }

        setSelectedRevisionOptions(formattedRevisionOptions)
        const planId = planMetadata.plan_id
        const preSelectedRevision = formatSelectedRevisionFromPath(location, paths)
        const findRevision = formattedRevisionOptions.find(
            (rev) => rev.value === preSelectedRevision.value,
        )
        let revisionId
        if (findRevision) {
            revisionId = findRevision.value
            setSelectedRevision(findRevision)
        } else {
            revisionId = planMetadata.revisions[0].revision_id
            setSelectedRevision(formattedRevisionOptions[0])
        }
        selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
            ? getParentPrograms()
            : getLocalPrograms(planId, revisionId!)
    }
    useEffect(() => {
        // get all orgs & Egret groups across
        getItemsApi(apiClient, '/orgs', setAllOrgs)
        getItemsApi(apiClient, '/groups/is_egret/true', setAllGroups, decompressResponse)
    }, [])

    useEffect(() => {
        getBusinessEntityPlans()
        setProgramBusinessEntityOptions([
            {
                label: 'All Programs',
                value: GLOBAL_BUSINESS_ENTITY,
            },
            ...(selectedBusinessEntity.business_entity_id.length
                ? [
                      {
                          label: selectedBusinessEntity.business_entity_name,
                          value: selectedBusinessEntity.business_entity_id,
                      },
                  ]
                : []),
        ])
        if (selectedBusinessEntity.business_entity_id) {
            setSelectedProgramBusinessEntity({
                label: selectedBusinessEntity.business_entity_name,
                value: selectedBusinessEntity.business_entity_id,
            })
        }
    }, [selectedBusinessEntity])

    useEffect(() => {
        if (selectedPlan && !createEditModalVisible) {
            BusinessEntityRefresh(selectedBusinessEntity.business_entity_id, loadPage)
        }
        if (isSidePanelVisible && commentMode === COMMENT_PANEL_MODE.EDIT) {
            setCommentMode(COMMENT_PANEL_MODE.VIEW)
        }
        getParentProgramGlobalAttributes(apiClient, selectedPlan, setAllGlobalAttributesForPlan)
        setSelectedFilters(generateInitialCommentFilters(userRoleSelections, selectedPlan?.value))
        generateCommentFilterOptions()
    }, [selectedPlan])

    useEffect(() => {
        if (
            !selectedRevision.value ||
            !selectedPlan.value?.revisions?.length ||
            createEditModalVisible
        ) {
            return
        }
        const selectedPlanRevisions = selectedPlan.value.revisions
        const currentRevision = selectedPlanRevisions.find(
            (planRev) => selectedRevision.value === planRev.revision_id,
        )
        if (!currentRevision) {
            return
        }
        getDeliverablesUnderProgram()
    }, [selectedRevision])

    const onSelectPlanChange = (newPlanOption) => {
        setSelectedPlan(newPlanOption)
        setPlanMetadata(newPlanOption.value)
        setSelectedRevisionOptions(
            (newPlanOption.value?.revisions || []).map((rev) => formatRevisionOptions(rev)),
        )
    }

    useEffect(() => {
        if (!planMetadata?.plan_id || !selectedRevision.value || createEditModalVisible) {
            return
        }
        setGlobalColumnDefinitions(
            getGlobalProgramColumnDefinitions(navigate, planMetadata, selectedRevision),
        )

        apiClient
            .get(`/settings?keys=${PROGRAM_SELECTION_IDS.join(',')}`)
            .then((res) => {
                const selections = res.data
                setLocalColumnDefinitions(
                    getProgramColumnDefinitions(
                        navigate,
                        planMetadata,
                        selectedRevision,
                        getFormattedAttributes(selections, programAttributes),
                    ),
                )
            })
            .catch((err) => {
                console.error(err)
            })
    }, [planMetadata, selectedRevision])

    const generateCommentFilterOptions = useCallback(() => {
        const programNameIdMap: { [key: string]: any } = {}
        const programOptions: MUIAutocompleteOption[] = []
        let deliverableOptions: MUIAutocompleteOption[] = []

        const orgOptions = formatCommentPanelSearchOptions(
            allOrgs,
            'org_name',
            'org_id',
            FILTER_PAYLOAD_TYPES.ORG,
        )
        const groupOptions = formatCommentPanelSearchOptions(
            allGroups,
            'group_name',
            'group_id',
            FILTER_PAYLOAD_TYPES.GROUP,
        )

        if (deliverablesList) {
            Object.keys(deliverablesList).forEach((progName) => {
                deliverableOptions = deliverableOptions.concat(
                    deliverablesList[progName].map((deliverable) => {
                        programNameIdMap[progName] = programNameMap[progName]?.parent_program_id
                        return {
                            label: `${deliverable.deliverable_name} - ${progName}`,
                            type: FILTER_PAYLOAD_TYPES.DELIVERABLE,
                            id: deliverable.deliverable_id,
                        }
                    }),
                )
            })
        }
        Object.keys(programNameIdMap).forEach((program) => {
            programOptions.push({
                label: program,
                type: FILTER_PAYLOAD_TYPES.PROGRAM,
                id: programNameIdMap[program],
            })
        })
        programOptions.sort((a, b) => a.label.localeCompare(b.label))
        deliverableOptions.sort((a: any, b: any) => a.label.localeCompare(b.label))

        setFilterOptions([...programOptions, ...deliverableOptions, ...orgOptions, ...groupOptions])
    }, [deliverablesList, selectedPlan, programs])

    return (
        <AppLayout
            disableContentPaddings={true}
            contentType={'table'}
            navigationHide={true}
            toolsHide={true}
            splitPanelOpen={isSidePanelVisible}
            splitPanelPreferences={{ position: 'side' }}
            splitPanel={
                selectedProgramBusinessEntity.value !== GLOBAL_BUSINESS_ENTITY && (
                    <CommentPanel
                        mode={commentMode}
                        onIsVisibleChange={setIsSidePanelVisible}
                        commentDeliverableOptions={commentDeliverableOptions}
                        parentProgramGlobalAttributes={allGlobalAttributesForPlan}
                        selectedRole={selectedRole}
                        onSelectedRoleChange={setSelectedRole}
                        selectedProgram={
                            selectedPrograms?.length
                                ? {
                                      value: selectedPrograms[0]?.parent_program_id,
                                      label: selectedPrograms[0]?.program_name,
                                  }
                                : DEFAULT_SELECTED_PROGRAM
                        }
                        selectedDeliverable={selectedCommentDeliverable}
                        onSelectedDeliverableChange={setSelectedCommentDeliverable}
                        selectedPlan={selectedPlan?.value}
                        allLocalProgramMap={allLocalProgramMap}
                        allOrgs={allOrgs}
                        allGroups={allGroups}
                        filterOptions={filterOptions}
                        selectedFilters={selectedFilters}
                        onSelectedFilterChanged={setSelectedFilters}
                    />
                )
            }
            content={
                <ContentLayout
                    defaultPadding
                    headerBackgroundStyle={HEADER_BG_COLOR}
                    headerVariant='high-contrast'
                    header={
                        <Box margin={{ top: 's', left: 's', right: 's' }}>
                            <SpaceBetween size='m'>
                                <HeaderTemplate
                                    subtitle={PROGRAM_PAGE_SUB_DESC}
                                    items={[
                                        { text: 'Home', href: '/' },
                                        ...(!location?.state
                                            ? []
                                            : [
                                                  { text: `Plans`, href: '/all-plans' },
                                                  {
                                                      text: `${selectedPlan?.value?.plan_type || ''} ${selectedPlan?.value?.year || ''} Revisions`,
                                                      href: `/plan/${selectedPlan?.value?.plan_id || ''}/revisions`,
                                                  },
                                              ]),
                                        {
                                            text:
                                                selectedProgramBusinessEntity.value !==
                                                GLOBAL_BUSINESS_ENTITY
                                                    ? `${selectedPlan?.value?.plan_type || ''} ${selectedPlan?.value?.year || ''}
                                    Programs`
                                                    : 'Programs',
                                            href: '',
                                        },
                                    ]}
                                />
                                {alertItems.length !== 0 && (
                                    <Flashbar items={alertItems} stackItems={true} />
                                )}
                                {selectedProgramBusinessEntity.value !== GLOBAL_BUSINESS_ENTITY && (
                                    <RevisionAlert
                                        planId={planMetadata?.plan_id || ''}
                                        selectedRevisionId={selectedRevision.value || ''}
                                        sourcePage={SOURCE_PAGE.PROGRAM}
                                        setIsLocked={setIsRevisionLocked}
                                        apiClient={apiClient}
                                    />
                                )}
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <CreateEditModal
                        isStlNotAdmin={isStlNotAdmin}
                        selectedRevision={selectedRevision}
                        selectedRevisionOptions={selectedRevisionOptions}
                        state={state}
                        selectedPlan={selectedPlan}
                        selectedPlanOptions={businessEntityPlans.map((plan: any) =>
                            formatPlanOptions(plan),
                        )}
                        onSelectPlanChange={onSelectPlanChange}
                        fetchGlobalAttributesForPlan={fetchGlobalAttributesForParentProgramAndPlan}
                        onSelectRevisionChange={(newRevisionOption) => {
                            setSelectedRevision(newRevisionOption)
                        }}
                        inputChange={inputChange}
                        clearAllInputs={clearAllInputs}
                        isDataUnmodified={isDataUnmodified}
                        onIsDataUnmodifiedChanged={setIsDataUnmodified}
                        isInputInvalid={isInputInvalid}
                        onIsInputInvalidChanged={setIsInputInvalid}
                        isAddingProgram={isAddingProgram}
                        onAlertItemAdd={handleAddAlertItem}
                        onDismiss={() => {
                            setCreateEditModalVisible(false)
                        }}
                        visible={createEditModalVisible}
                        onIsLoadingChange={setIsLoading}
                        isModalExpand={isModalExpand}
                        onIsModalExpandChange={setIsModalExpand}
                        modalMode={modalMode}
                        programs={programs}
                        plans={businessEntityPlans}
                        selectedPrograms={selectedPrograms}
                        onSelectedProgramsChange={setSelectedPrograms}
                        selectedProgramBusinessEntity={selectedProgramBusinessEntity}
                        getLocalPrograms={getLocalPrograms}
                        getParentPrograms={getParentPrograms}
                        initialKingpinGoals={initialKingpinGoals}
                        initialImportantLinks={initialImportantLinks}
                        allParentProgramPlans={parentProgramAllPlans}
                        allParentProgramGlobalAttributes={parentProgramAllGlobalAttributes}
                        isLoadingGlobalAttrs={isLoadingParentProgramGlobalAttrs}
                        isLoadingParentProgramPlans={isLoadingParentProgramPlans}
                        globalAttributesError={globalAttributesError}
                        setGlobalAttributesError={setGlobalAttributesError}
                        loadSelectProgram={loadSelectProgram}
                        refreshPage={loadPage}
                        hideLocalAttributes={false}
                    />
                    <DeleteModal
                        titleOverride={
                            selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
                                ? `Delete Global Program`
                                : `Remove from Current Revision`
                        }
                        descriptionOverride={
                            selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
                                ? `Delete permanently? Admins will no longer be able to add this program to plan revision again.`
                                : `Remove from Revision ${revisionMap.get(selectedRevision.value)?.revision_number || ''}`
                        }
                        buttonOverride={
                            selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
                                ? 'Delete'
                                : 'Remove'
                        }
                        visible={deleteModalVisible}
                        onDismiss={() => setDeleteModalVisible(false)}
                        onDelete={() => handleDeleteSubmit()}
                    />
                    <Box margin={{ left: 's', right: 's' }}>
                        <ProgramTable
                            isStlNotAdmin={isStlNotAdmin}
                            selectedRevision={selectedRevision}
                            onSelectedRevisionChange={setSelectedRevision}
                            selectedRevisionOptions={selectedRevisionOptions}
                            programs={programs}
                            selectedPlan={selectedPlan}
                            selectedPlanOptions={businessEntityPlans.map((plan: any) =>
                                formatPlanOptions(plan),
                            )}
                            onSelectedPlanChange={onSelectPlanChange}
                            getLocalPrograms={getLocalPrograms}
                            getParentPrograms={getParentPrograms}
                            selectedPrograms={selectedPrograms}
                            onSelectedProgramsChange={setSelectedPrograms}
                            isRevisionLocked={isRevisionLocked}
                            onCreate={handleClickCreate}
                            onEdit={handleClickEdit}
                            onView={handleClickView}
                            onDelete={handleClickDelete}
                            onIsModalExpandChange={setIsModalExpand}
                            isLoading={isLoading}
                            onIsAddingProgramChange={setIsAddingProgram}
                            selectedProgramBusinessEntity={selectedProgramBusinessEntity}
                            onSelectedProgramBusinessEntityChange={setSelectedProgramBusinessEntity}
                            programBusinessEntityOptions={programBusinessEntityOptions}
                            columnDefinitions={
                                selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
                                    ? globalColumnDefinitions
                                    : localColumnDefinitions
                            }
                            visibleColumns={
                                selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
                                    ? globalVisibleColumns
                                    : localVisibleColumns
                            }
                            exportVisibleColumns={
                                selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
                                    ? getProgramTableExportGlobalVisibleColumns()
                                    : getProgramTableExportLocalVisibleColumns()
                            }
                            deliverables={deliverablesList}
                            fetchGlobalAttributes={fetchGlobalAttributesForParentProgramAndPlan}
                            setIsLoadingGlobalAttrs={setIsLoadingParentProgramGlobalAttrs}
                            isSidePanelVisible={isSidePanelVisible}
                            onSidePanelVisibleChange={setIsSidePanelVisible}
                            commentMode={commentMode}
                            onCommentModeChange={setCommentMode}
                            onSelectedRoleChange={setSelectedRole}
                        />
                    </Box>
                </ContentLayout>
            }
        ></AppLayout>
    )
}

export default ProgramPage
