import { SelectProps } from '@amzn/awsui-components-react'
import { useEffect, useReducer } from 'react'
import useStore from '../../Store'
import { useAppContext } from '../../../context'
import { decompressResponse, getOrgOptions } from '../../common/Util'
import { formatPlanOptionsWithData, formatRevisionOptions } from '../reusable/Utils'
import _ from 'lodash'
import { useQuery } from '../../UseQuery'
import { PROGRAM } from './PreferencesUtil'
import { PLAN, REVISION } from '../../Constant'
import { sortPlanOptionsWithData } from '../program/ProgramSharedUtils'

export interface ExtendedPropsOption extends SelectProps.Option {
    data: any
}

interface SummaryItemData {
    options: SelectProps.Option[] | ExtendedPropsOption[]
    selected: SelectProps.Option | ExtendedPropsOption
    loading: boolean
}

export interface SummaryData {
    showScopedOnly: boolean
    plans: SummaryItemData
    orgs: SummaryItemData
    revisions: SummaryItemData
    rowData: {
        data: any[]
    }
    deliverables: {
        data: any[]
        loading: boolean
    }
    spend: {
        data: any
        loading: boolean
    }
    gridApi: any
    programGridApi: any
    headcount: {
        loading: boolean
        data: any[]
    }
    programs: {
        data: any[]
        options?: SelectProps.Option[] | ExtendedPropsOption[]
        selected?: SelectProps.Option | ExtendedPropsOption
    }
    groupsByOrg?: {
        data: any[]
        loading: boolean
        orgIdToNameMap: any
        orgNameToIdMap: any
        groupMap: any
        orgNameToGroupList: any
    }
    businessEntity: string
}

export enum ACTION_TYPES {
    PLAN = 'plans',
    REVISION = 'revisions',
    ORG_GROUP = 'orgGroups',
    ORG = 'orgs',
    DELIVERABLES = 'deliverables',
    HC = 'headcount',
    SPEND = 'spend',
    PROGRAM = 'programs',
    GROUPS_BY_ORG = 'groupsByOrg',
    SCOPED = 'showScopedOnly',
    BE = 'businessEntity',
    ROW_DATA = 'rowData',
}

const reducer = (state, action) => {
    // If action.type is valid
    if (action.type === ACTION_TYPES.SCOPED || action.type === ACTION_TYPES.BE) {
        return {
            ...state,
            [action.type]: action.payload,
        }
    } else if (Object.values(ACTION_TYPES).includes(action.type)) {
        return {
            ...state,
            [action.type]: {
                ...state[action.type],
                [action.payload.key]: action.payload.data,
            },
        }
    } else if (action.type === 'SET_MANY') {
        const newState = { ...state }
        Object.keys(action.payload).forEach((key) => {
            action.payload[key].forEach((item) => {
                newState[key][item.key] = item.data
            })
        })
        return newState
    } else if (action.type === 'GRID_API') {
        return {
            ...state,
            gridApi: action.payload,
        }
    } else if (action.type === 'PROGRAM_GRID_API') {
        return {
            ...state,
            programGridApi: action.payload,
        }
    } else {
        return state
    }
}

export function useNewSummaryData() {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const query = useQuery()
    const qsProgram = query.get(PROGRAM)
    const qsRevision = query.get(REVISION)
    const qsPlan = query.get(PLAN)

    const summaryData: SummaryData = {
        showScopedOnly: true,
        plans: {
            options: [],
            selected: {},
            loading: false,
        },
        rowData: {
            data: [],
        },
        deliverables: {
            data: [],
            loading: false,
        },
        orgs: {
            options: [],
            selected: { label: 'Select Org', value: '' },
            loading: false,
        },
        revisions: {
            options: [],
            selected: { label: 'Select Revision', value: '' },
            loading: false,
        },
        gridApi: null,
        programGridApi: null,
        headcount: { data: [], loading: false },
        spend: {
            data: {},
            loading: false,
        },
        programs: {
            data: [],
            options: [],
            selected: { label: 'Select Program', value: '' },
        },
        groupsByOrg: {
            data: [],
            loading: false,
            groupMap: new Map(),
            orgIdToNameMap: new Map(),
            orgNameToIdMap: new Map(),
            orgNameToGroupList: {},
        },
        businessEntity: selectedBusinessEntity,
    }

    const [data, dispatch] = useReducer(reducer, summaryData)
    const planId = data[ACTION_TYPES.PLAN].selected?.data?.plan_id
    const revisionId = data[ACTION_TYPES.REVISION].selected.value

    useEffect(() => {
        if (data[ACTION_TYPES.ORG].options.length) {
            dispatch({
                type: ACTION_TYPES.ORG,
                payload: { key: 'selected', data: { ...data[ACTION_TYPES.ORG].options[0] } },
            })
        }
    }, [data[ACTION_TYPES.ORG].options])

    useEffect(() => {
        if (data[ACTION_TYPES.REVISION].options.length) {
            const initialRevision = qsRevision
                ? data[ACTION_TYPES.REVISION].options.find((option) => option.value === qsRevision)
                : null
            dispatch({
                type: ACTION_TYPES.REVISION,
                payload: {
                    key: 'selected',
                    data: initialRevision
                        ? { ...initialRevision }
                        : { ...data[ACTION_TYPES.REVISION].options[0] },
                },
            })
        }
    }, [data[ACTION_TYPES.REVISION].options])

    useEffect(() => {
        const planId = data[ACTION_TYPES.PLAN].selected.value
        if (!planId) {
            return
        }
        getPlanData(planId)
    }, [data[ACTION_TYPES.PLAN].selected])

    useEffect(() => {
        const businessEntityId = selectedBusinessEntity?.business_entity_id
        if (!businessEntityId) {
            return
        }
        dispatch({ type: ACTION_TYPES.PLAN, payload: { key: 'loading', data: true } })
        dispatch({ type: ACTION_TYPES.BE, payload: selectedBusinessEntity })
        getOrgs(businessEntityId)
        getAllOrgGroups(businessEntityId)
        getBusinessEntityPlans(businessEntityId)
    }, [selectedBusinessEntity])

    useEffect(() => {
        if (!data[ACTION_TYPES.PLAN].selected || !data[ACTION_TYPES.REVISION].selected) {
            return
        }

        dispatch({ type: ACTION_TYPES.DELIVERABLES, payload: { key: 'loading', data: true } })
        getPlanDeliverables(planId, revisionId)
        dispatch({
            type: 'SET_MANY',
            payload: {
                [ACTION_TYPES.SPEND]: [
                    {
                        key: 'loading',
                        data: true,
                    },
                ],
            },
        })
        getProgramData(planId, revisionId)
        getSpendDataForPlanAndRevision(planId, revisionId)
    }, [data[ACTION_TYPES.REVISION].selected])

    useEffect(() => {
        if (
            data[ACTION_TYPES.PLAN].selected.data &&
            data[ACTION_TYPES.REVISION].selected &&
            data[ACTION_TYPES.ORG].options
        ) {
            queryHCEstimateAPI(
                data[ACTION_TYPES.PLAN].selected.data.plan_id,
                data[ACTION_TYPES.REVISION].selected.value,
                data[ACTION_TYPES.ORG].options,
            )
        }
    }, [
        data[ACTION_TYPES.PLAN].selected,
        data[ACTION_TYPES.REVISION].selected,
        data[ACTION_TYPES.ORG].options,
    ])

    const getPlanData = (planId: string) => {
        apiClient
            .get(`/plan/${planId}`)
            .then((res) => {
                if (res.data.revisions.length) {
                    const formattedRevisions = res.data.revisions.map(formatRevisionOptions)
                    dispatch({
                        type: ACTION_TYPES.REVISION,
                        payload: { key: 'options', data: formattedRevisions },
                    })
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getBusinessEntityPlans = (businessEntityId) => {
        apiClient
            .get(`/plan/business-entity/${businessEntityId}?year=`)
            .then((res) => {
                const formattedPlanOptions = sortPlanOptionsWithData(
                    res.data.map((plan) => formatPlanOptionsWithData(plan)),
                )
                if (formattedPlanOptions.length) {
                    const initialPlan = qsPlan
                        ? formattedPlanOptions.find((option) => option.value === qsPlan)
                        : null
                    const selectedPlan = initialPlan || formattedPlanOptions[0]
                    const revisionOptions = selectedPlan.data.revisions.map((rev: any) =>
                        formatRevisionOptions(rev),
                    )
                    dispatch({
                        type: 'SET_MANY',
                        payload: {
                            [ACTION_TYPES.REVISION]: [{ key: 'options', data: revisionOptions }],
                            [ACTION_TYPES.PLAN]: [
                                { key: 'selected', data: selectedPlan },
                                { key: 'options', data: formattedPlanOptions },
                            ],
                        },
                    })
                } else {
                    dispatch({
                        type: 'SET_MANY',
                        payload: {
                            [ACTION_TYPES.REVISION]: { key: 'options', data: [] },
                            [ACTION_TYPES.PLAN]: [
                                { key: 'selected', data: {} },
                                { key: 'options', data: [] },
                            ],
                        },
                    })
                }
            })
            .catch((err) => {
                console.error(err)
                dispatch({ type: ACTION_TYPES.PLAN, payload: { key: 'options', data: [] } })
            })
        dispatch({ type: ACTION_TYPES.PLAN, payload: { key: 'loading', data: false } })
    }

    const getOrgs = (businessEntityId: string) => {
        apiClient
            .get(`/falcon/business-entity/${businessEntityId}/orgs`)
            .then((res) => {
                const allOrgs = res.data.filter((org) => org.is_active)
                dispatch({
                    type: ACTION_TYPES.ORG,
                    payload: { key: 'options', data: getOrgOptions(allOrgs) },
                })
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getAllOrgGroups = (businessEntityId: string) => {
        const groupsByOrg: any = []
        const orgIdToOrgNameList: any[] = []
        const orgNameToOrgIdList: any[] = []
        const groupIdToGroupList: any[] = []
        dispatch({
            type: ACTION_TYPES.GROUPS_BY_ORG,
            payload: { key: 'loading', data: true },
        })
        apiClient
            .get(`/business_entity/${businessEntityId}/groups`)
            .then((res) => {
                const orgs = res.data

                for (const org in orgs) {
                    const groups = (orgs[org] ?? [])
                        .filter((group) => group.is_active && group.is_egret)
                        .sort((a, b) => a.group_name.localeCompare(b.group_name))

                    if (groups.length) {
                        orgIdToOrgNameList.push([
                            groups[0].org_id,
                            { org_name: groups[0].org_name },
                        ])
                        orgNameToOrgIdList.push([groups[0].org_name, { org_id: groups[0].org_id }])
                        groupsByOrg.push({
                            orgName: groups[0].org_name,
                            orgId: groups[0].org_id,
                            groups: groups,
                        })
                        groups.forEach((group) => groupIdToGroupList.push([group.group_id, group]))
                    }
                }
                dispatch({
                    type: 'SET_MANY',
                    payload: {
                        [ACTION_TYPES.GROUPS_BY_ORG]: [
                            { key: 'data', data: groupsByOrg },
                            { key: 'orgIdToNameMap', data: new Map(orgIdToOrgNameList) },
                            { key: 'orgNameToIdMap', data: new Map(orgNameToOrgIdList) },
                            { key: 'groupMap', data: new Map(groupIdToGroupList) },
                            { key: 'orgNameToGroupList', data: orgs },
                        ],
                    },
                })
            })
            .catch((err) => console.error(err))
            .finally(() => {
                dispatch({
                    type: ACTION_TYPES.GROUPS_BY_ORG,
                    payload: { key: 'loading', data: false },
                })
            })
    }

    const getPlanDeliverables = (planId: string, revisionId: string) => {
        if (!revisionId && !planId) {
            return
        }

        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/deliverables`)
            .then((res) => {
                dispatch({
                    type: 'SET_MANY',
                    payload: {
                        [ACTION_TYPES.DELIVERABLES]: [
                            { key: 'data', data: res.data },
                            { key: 'loading', data: false },
                        ],
                    },
                })
            })
            .catch((e) => {
                console.error(e)
                dispatch({
                    type: ACTION_TYPES.DELIVERABLES,
                    payload: { key: 'loading', data: false },
                })
            })
    }

    const getSpendDataForPlanAndRevision = (planId: string, revisionId: string) => {
        if (!planId || !revisionId) {
            return
        }
        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/discretionary-spend`)
            .then((res) => {
                const spendEstimates = decompressResponse(res.data)
                const spendEstimatesByOrg = {}
                spendEstimates.forEach((estimate) => {
                    if (!spendEstimatesByOrg[estimate.org_id]) {
                        spendEstimatesByOrg[estimate.org_id] = []
                    }
                    spendEstimatesByOrg[estimate.org_id].push(estimate)
                })
                dispatch({
                    type: 'SET_MANY',
                    payload: {
                        [ACTION_TYPES.SPEND]: [
                            { key: 'loading', data: false },
                            { key: 'data', data: spendEstimatesByOrg },
                        ],
                    },
                })
            })
            .catch((err) => console.error(err))
    }

    const getProgramData = (planId: string, revisionId: string) => {
        if (!planId || !revisionId) {
            return
        }

        dispatch({ type: ACTION_TYPES.PROGRAM, payload: { key: 'loading', data: true } })

        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/programs`)
            .then((res) => {
                const programOptions = res.data
                    .sort((a: any, b: any) => a.program_name.localeCompare(b.program_name))
                    .map((program: any) => ({
                        label: program.program_name,
                        value: program.program_id,
                        data: program,
                    }))

                const programData = {}
                res.data.forEach((program) => {
                    programData[program.program_id] = program
                })

                const selectedProgram = (() => {
                    if (!_.isEmpty(programOptions)) {
                        if (qsProgram) {
                            return (
                                programOptions.find((option) => option.value === qsProgram) ||
                                programOptions[0]
                            )
                        } else {
                            return programOptions[0]
                        }
                    } else {
                        return { label: 'Select Program', value: '' }
                    }
                })()

                dispatch({
                    type: 'SET_MANY',
                    payload: {
                        [ACTION_TYPES.PROGRAM]: [
                            { key: 'data', data: programData },
                            { key: 'options', data: programOptions },
                            {
                                key: 'selected',
                                data: selectedProgram,
                            },
                        ],
                    },
                })
            })
            .catch((e) => {
                console.error(e)
            })
            .finally(() => {
                dispatch({ type: ACTION_TYPES.PROGRAM, payload: { key: 'loading', data: false } })
            })
    }

    const queryHCEstimateAPI = async (
        planId: string | undefined,
        revisionId: string | undefined,
        orgOptions: any[],
    ) => {
        dispatch({ type: ACTION_TYPES.HC, payload: { key: 'loading', data: true } })

        // TODO Error handling
        const resData = await Promise.all(
            orgOptions.map((org) => {
                return apiClient.get(
                    `/org-headcount-estimates/org/${org.value}/plan/${planId}/revision/${revisionId}`,
                )
            }),
        )
        const flatData = resData.map((res) => res.data).flat()

        dispatch({
            type: 'SET_MANY',
            payload: {
                [ACTION_TYPES.HC]: [
                    { key: 'data', data: flatData },
                    { key: 'loading', data: false },
                ],
            },
        })
    }

    return {
        data,
        dispatch,
    }
}
