import { TextContent } from '@amzn/awsui-components-react'
import { isCellById } from '../program/SandboxUtils'
import { addCommentMenuItems } from '../comment/CommentUtil'

export const GRAND_TOTAL_COLOR_CODE = '#AEAEAE'
export const NORMAL_CELL_COLOR_CODE = '#FFFFFF'
export const HISTORY_BADGE_BLUE = '#B5D6F4'

export const HISTORY_BADGE_GREY = '#D1D5DB'

export const HISTORY_PANEL_ORG_MAX_WIDTH = 10
export const HISTORY_PANEL_GROUP_MAX_TEXT_LENGTH = 25
export const HISTORY_PANEL_PROG_DELI_MAX_TEXT_LENGTH = 15

export const hideZerosAndTruncateNumbers = (params) => {
    return params.value !== 0 ? params.value?.toFixed(2) : ''
}

export const generateCellTooltip = (params) => {
    const colId = params.column.colId
    if (params.data) {
        if (params.data[`${colId}_locked`] !== undefined && params.data[`${colId}_locked`]) {
            return params.data[`${colId}_locked_user`]
        } else if (params.data[colId] != undefined) {
            return `Previous Value: ${params.data[`${colId}_previous_value`]} \v Updated By: ${params.data[`${colId}_updated_by`]} \v Updated At: ${params.data[`${colId}_updated_at`]?.split('T')[0]}`
        } else {
            return
        }
    }
}

export const formatSelection = (object): any => {
    return {
        value: object.value,
        label: (
            <TextContent>
                <p> {`${object.label}`} </p>
            </TextContent>
        ),
    }
}

export const visibleColumns = ['calculation', 'hc', 'ct', 'ff']
export const HC_GAP = 'Gap (Incremental HC Ask)'

export const orgSummaryInitialColDef: any[] = [
    {
        headerName: 'Program',
        field: 'program_name',
        rowGroup: true,
        hide: true,
        pinned: 'left',
        headerTooltip: 'Program',
    },
    {
        headerName: 'Deliverable',
        field: 'deliverable_name',
        pinned: 'left',
        headerTooltip: 'Deliverable',
    },
]

export const getSummaryGetContextMenuItemsGridOption = (
    params,
    userRolesMetadata,
    setCommentMode,
    setIsSidePanelVisible,
    setSelectedProgram,
    setSelectedRole,
    setSelectedDeliverable,
    setCommentDeliverableOptions,
    selectedPlan,
    stage,
) => {
    return isCellById(params, 'program_name_and_priority') || isCellById(params, 'program_name')
        ? [
              addCommentMenuItems(
                  true,
                  userRolesMetadata,
                  setCommentMode,
                  setIsSidePanelVisible,
                  setSelectedProgram,
                  setSelectedRole,
                  setSelectedDeliverable,
                  setCommentDeliverableOptions,
                  selectedPlan,
              ),
          ]
        : isCellById(params, 'deliverable_name')
          ? [
                addCommentMenuItems(
                    false,
                    userRolesMetadata,
                    setCommentMode,
                    setIsSidePanelVisible,
                    setSelectedProgram,
                    setSelectedRole,
                    setSelectedDeliverable,
                    setCommentDeliverableOptions,
                    selectedPlan,
                ),
            ]
          : []
}
