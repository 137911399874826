import { OBJECT_TYPES } from './Constant'
import { AUDIT_STATE_ACTIONS } from './useAuditState'
import { mergeTwoMaps } from '../../common/Util'

export const fetchArchivedItems = (
    apiClient: any,
    dispatch: any,
    itemType: OBJECT_TYPES,
    mapKey: string,
    planId?,
) => {
    const apiPath = `/archive/${itemType.toLowerCase()}?plan_id=${planId}`
    apiClient
        .get(apiPath)
        .then((res) => {
            dispatch({
                type: AUDIT_STATE_ACTIONS.ADD_ARCHIVE_ITEMS,
                payload: {
                    archiveData: res.data,
                    mapKey: mapKey,
                    objectType:
                        itemType !== OBJECT_TYPES.REVISION
                            ? itemType
                            : OBJECT_TYPES.ARCHIVED_REVISION,
                    planId: planId,
                },
            })
        })
        .catch((err) => {
            console.error(err)
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_MANY,
                payload: {
                    [mapKey]: {
                        loading: false,
                    },
                },
            })
        })
}

export const addArchivedItems = (currentMap, archivedItems, valProcessor?: any) => {
    const newMapEntries: any[] = []
    currentMap.forEach((val, key, map) => {
        newMapEntries.push([key, val])
    })
    archivedItems.forEach((archivedItem) => {
        const itemId = archivedItem['object_sort'].split('#')[1]
        if (!currentMap.has(itemId)) {
            let valToSave = archivedItem['attributes']
            if (valProcessor) {
                valToSave = valProcessor(archivedItem['attributes'])
            }
            newMapEntries.push([itemId, valToSave])
        }
    })
    return new Map(newMapEntries)
}

export const getAllRevisionsFromStateAndPlan = (state, selectedPlanId) => {
    const planRevisions = state.plansMap.data.get(selectedPlanId)?.revisions ?? []
    const planRevisionMap = new Map(planRevisions.map((rev) => [rev.revision_id, rev]))
    const archivedRevisionsMap = state.archivedRevisionsByPlan.data[selectedPlanId] ?? new Map()
    return mergeTwoMaps(planRevisionMap, archivedRevisionsMap)
}
