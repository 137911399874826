import {
    Box,
    Button,
    Drawer,
    Grid,
    Header,
    Select,
    SpaceBetween,
    Spinner,
} from '@amzn/awsui-components-react'
import Quill from 'quill'
import useStore from '../../Store'
import { Autocomplete, Chip, TextField, Typography } from '@mui/material'
import {
    generateCommentDivId,
    generateCommentForms,
    generateCommentSubMenu,
    generateDeletedCommentText,
    getProgramDeliverableMapByParentProgramGlobalAttributes,
    setCommentsDivBottomMargin,
    generateInactiveCommentText,
    generateFilterPayload,
} from './CommentUtil'
import {
    Comment,
    COMMENT_DIV_CLASS,
    COMMENT_PANEL_MODE,
    FILTER_PAYLOAD_TYPES,
    CommentFilter,
    MUIAutocompleteOption,
} from './Constant'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import './RichTextCommentInput.scss'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import { useAppContext } from '../../../context'
import CommentAutoRefresh from './CommentAutoRefresh'
import RichTextCommentInput from './RichTextCommentInput'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'

const NUM_PAGINATED_COMMENTS = 10

interface CommentPanelProps {
    mode: COMMENT_PANEL_MODE
    onIsVisibleChange: Dispatch<SetStateAction<boolean>>
    commentDeliverableOptions: any[]
    selectedRole: SelectProps.Option
    onSelectedRoleChange: Dispatch<SetStateAction<SelectProps.Option>>
    selectedProgram: SelectProps.Option
    selectedDeliverable: SelectProps.Option
    onSelectedDeliverableChange: Dispatch<SetStateAction<SelectProps.Option>>
    selectedPlan: any
    parentProgramGlobalAttributes: any[]
    allLocalProgramMap: any
    allOrgs: any[]
    allGroups: any[]
    filterOptions: CommentFilter[]
    selectedFilters: CommentFilter[]
    onSelectedFilterChanged: Dispatch<SetStateAction<CommentFilter[]>>
}

interface PaginatedComments {
    items: Comment[]
    total: number
    pageIdx: number
    perPage: number
    totalPages: number
}

const CommentPanel = (props: CommentPanelProps) => {
    const {
        mode,
        onIsVisibleChange,
        commentDeliverableOptions,
        selectedRole,
        onSelectedRoleChange,
        selectedProgram,
        selectedDeliverable,
        onSelectedDeliverableChange,
        parentProgramGlobalAttributes,
        allLocalProgramMap,
        allOrgs,
        allGroups,
        selectedPlan,
        filterOptions,
        selectedFilters,
        onSelectedFilterChanged,
    } = props
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userProps.userAlias ?? 'user'

    const userRolesMetadata = useStore((state) => state.userRolesMetadata)
    const [userRoleSelections, setUserRoleSelections] = useState<SelectProps.Option[]>([])
    const [programMapName, deliverableMap] =
        getProgramDeliverableMapByParentProgramGlobalAttributes(parentProgramGlobalAttributes)
    const [localComments, setLocalComments] = useState<Comment[]>([])

    const [commentPaginated, setCommentPaginated] = useState<PaginatedComments>({
        items: [],
        total: 0,
        pageIdx: 0,
        perPage: 0,
        totalPages: 0,
    })

    const getPaginatedComments = (refreshPageIdx: boolean, numCommentsToPull: number) => {
        const filters = generateFilterPayload(selectedFilters, selectedPlan)
        const queryPageIdx = refreshPageIdx ? 1 : commentPaginated?.pageIdx + 1
        apiClient
            .post(
                `/query-comment/page_idx/${queryPageIdx}/num_comments/${numCommentsToPull}`,
                JSON.stringify(filters),
            )
            .then((res) => {
                const commentData = res.data
                setCommentPaginated({
                    items: commentData.items,
                    total: parseInt(commentData.total),
                    pageIdx: parseInt(commentData.page),
                    perPage: parseInt(commentData.per_page),
                    totalPages: parseInt(commentData.total_pages),
                })
            })
            .catch((e) => {
                console.error(e)
            })
    }
    const footers = document.getElementsByClassName('comment-drawer-sticky-footer')
    const footerComponent = footers?.length ? (footers[0] as HTMLElement) : undefined

    useEffect(() => {
        const commentDrawers = document.getElementsByClassName('comment-drawer-comments')
        const commentDrawer = commentDrawers?.length
            ? (commentDrawers[0] as HTMLElement)
            : undefined
        if (footerComponent !== undefined && commentDrawer !== undefined) {
            commentDrawer.style.marginBottom = `${footerComponent.offsetHeight}px`
        }
    }, [footerComponent?.offsetHeight])

    useEffect(() => {
        if (_.isEmpty(selectedPlan)) {
            return
        }
        setUserRoleSelections(
            generateCommentSubMenu(userRolesMetadata, () => {}, selectedPlan).map((role) => {
                return {
                    label: role.name,
                    value: role.id,
                }
            }),
        )
    }, [userRolesMetadata, selectedPlan])

    useEffect(() => {
        setCommentsDivBottomMargin(mode)
    }, [mode])

    useEffect(() => {
        localComments.forEach((comment: Comment) => {
            const quill = new Quill(`#${generateCommentDivId(comment.comment_id)}`, {
                theme: 'bubble',
                modules: {
                    toolbar: false,
                },
                readOnly: true,
            })
            let commentText = JSON.parse(comment.text)
            const commentTextArr = commentText['ops']
            if (comment?.deleted) {
                commentText = generateDeletedCommentText(commentTextArr)
            } else if (allLocalProgramMap[comment.parent_program_id]?.is_active === false) {
                commentText = generateInactiveCommentText(commentTextArr, true)
            } else if (deliverableMap[comment.deliverable_id]?.is_active === false) {
                commentText = generateInactiveCommentText(commentTextArr, false)
            }
            quill.setContents(commentText)
        })
    }, [localComments])

    useEffect(() => {
        // Update local comments based on pagination
        // If it's the first page, replace all comments
        // If it's a subsequent page, append new comments
        const { pageIdx, items } = commentPaginated
        setLocalComments((prevComments) => (pageIdx === 1 ? items : [...prevComments, ...items]))
    }, [commentPaginated])

    useEffect(() => {
        if (_.isEmpty(selectedPlan)) {
            return
        }
        const refreshPageIdx = true
        getPaginatedComments(refreshPageIdx, NUM_PAGINATED_COMMENTS)
    }, [selectedPlan, selectedFilters])

    const handlePagination = useCallback(() => {
        // Pull NUM_PAGINATED_COMMENTS number of comments but
        // do not refresh the page idx
        const refreshPageIdx = false
        return getPaginatedComments(refreshPageIdx, NUM_PAGINATED_COMMENTS)
    }, [commentPaginated])

    const refreshedComments = useCallback(() => {
        // For the case when user wants to add a new comment or check for new comments
        // we set refreshPageIdx to true and pull comments that are in the local cache
        const refreshPageIdx = true
        return getPaginatedComments(refreshPageIdx, localComments.length)
    }, [localComments])

    const addUserAliasToFilterOptions = useCallback(
        (filterOptions, localComments) => {
            const userAliasMap: { [key: string]: MUIAutocompleteOption } = {}
            localComments?.forEach((comments) => {
                const userAlias = comments.user_alias
                if (!(userAlias in userAliasMap)) {
                    userAliasMap[userAlias] = {
                        label: userAlias,
                        type: FILTER_PAYLOAD_TYPES.USER_ALIAS,
                        id: userAlias,
                    }
                }
            })
            return filterOptions.concat(Object.values(userAliasMap))
        },
        [localComments, filterOptions],
    )

    return (
        <div
            id={'main-div'}
            style={{
                height: '90vh',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'scroll',
                overscrollBehavior: 'contain',
            }}
        >
            <Drawer
                id={'main-comment-drawer'}
                header={
                    <Header
                        variant={'h3'}
                        actions={
                            <Button
                                iconName='close'
                                variant='icon'
                                iconAlign={'right'}
                                onClick={() => {
                                    onIsVisibleChange(false)
                                }}
                            />
                        }
                    >
                        Comment
                    </Header>
                }
            >
                <div className={'comment-drawer'}>
                    <div className={'comment-drawer-search'}>
                        <SpaceBetween size='xs' direction='vertical'>
                            <Autocomplete
                                multiple
                                size='small'
                                value={selectedFilters}
                                options={addUserAliasToFilterOptions(filterOptions, localComments)}
                                groupBy={(option: CommentFilter) => option.type}
                                onChange={(_, newValue) => onSelectedFilterChanged(newValue)}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => {
                                        const { key, ...tagProps } = getTagProps({ index })
                                        return <Chip key={key} label={option.label} {...tagProps} />
                                    })
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.type === value.type && option.label === value.label
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { fontSize: '14px' },
                                        }}
                                        label='Search Comment(s)'
                                        placeholder='Orgs, Groups, Programs, Deliverables, Alias'
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <Typography sx={{ fontSize: '12px' }}>
                                            {option.label}
                                        </Typography>
                                    </li>
                                )}
                            />
                            {mode === COMMENT_PANEL_MODE.EDIT && (
                                <SpaceBetween size='m' direction='vertical'>
                                    <Box variant={'h3'}>{selectedProgram.label}</Box>
                                    <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                                        <Box fontWeight='bold'>Your Role</Box>
                                        <Select
                                            selectedOption={selectedRole}
                                            onChange={({ detail }) =>
                                                onSelectedRoleChange(detail.selectedOption)
                                            }
                                            options={userRoleSelections}
                                            filteringType='auto'
                                            expandToViewport
                                        />
                                    </Grid>
                                    <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
                                        <SpaceBetween size='xxxs' direction='vertical'>
                                            <Box fontWeight='bold'>
                                                Deliverable<i>-optional</i>
                                            </Box>
                                        </SpaceBetween>
                                        <Select
                                            selectedOption={selectedDeliverable}
                                            onChange={({ detail }) =>
                                                onSelectedDeliverableChange(detail.selectedOption)
                                            }
                                            options={commentDeliverableOptions}
                                            filteringType='auto'
                                            expandToViewport
                                        />
                                    </Grid>
                                </SpaceBetween>
                            )}
                        </SpaceBetween>
                        <hr />
                    </div>
                    <div id={COMMENT_DIV_CLASS} className={COMMENT_DIV_CLASS}>
                        <InfiniteScroll
                            dataLength={localComments?.length || 0}
                            next={handlePagination}
                            hasMore={commentPaginated['total'] > localComments?.length}
                            loader={
                                <p style={{ textAlign: 'center' }}>
                                    <b>Loading more comments</b>
                                    <Spinner />
                                </p>
                            }
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>You're all caught up!</b>
                                </p>
                            }
                            scrollableTarget={'main-div'}
                        >
                            {generateCommentForms(
                                apiClient,
                                userAlias,
                                localComments,
                                refreshedComments,
                                programMapName,
                                deliverableMap,
                                allOrgs,
                                allGroups,
                            )}
                        </InfiniteScroll>
                        <CommentAutoRefresh callBack={refreshedComments} comments={localComments} />
                    </div>
                    {mode === COMMENT_PANEL_MODE.EDIT && (
                        <div className={'comment-drawer-sticky-footer'}>
                            <RichTextCommentInput
                                refreshComments={refreshedComments}
                                selectedRole={selectedRole}
                                selectedDeliverable={selectedDeliverable}
                                selectedProgram={selectedProgram}
                                selectedPlan={selectedPlan}
                            />
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    )
}
export default CommentPanel
