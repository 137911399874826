import { ACTION_TYPES } from './useNewSummaryData'
import { SUMMARY_TABS } from '../../Constant'

export const COLUMN_PREFERENCES = [
    { id: 'C&T', label: 'C&T' },
    { id: 'FF', label: 'FF' },
    { id: 'Total', label: 'Org Totals' },
]

export const ROW_PREFERENCES = [
    { id: 'total', label: 'Total' },
    { id: 'totalOh', label: 'Total with OH' },
    { id: 'bis', label: 'BIS' },
    { id: 'gap', label: 'Gap' },
]

export const PROGRAM_ROW_PREFERENCES = [{ id: 'total', label: 'Total' }]

export const COL_LIST = ['Total', 'C&T', 'FF']
export const ROW_LIST = ['totalOh', 'bis', 'gap', 'total']
export const PROGRAM_ROW_LIST = ['total']

/* Enforcing limit for max orgs or groups that can be selected
as preferences to stay under url size limit of 2048 characters */
export const PREFERENCE_LIMIT = 40
export const TREE_SELECT_WIDTH = '83%'
export const TAB = 'Tab'
export const ROWS = 'Rows'
export const COLUMNS = 'Columns'
export const PROGRAM = 'Program'
export const REVISION = 'Revision'
export const PLAN = 'Plan'
export const GRAND_TOTAL_ROW = 'grandTotalRow'
export const MIN_GROUP_ALERT = 'You must have at least 1 group selected'
export const MIN_ORG_ALERT = 'You must have at least 1 org selected'
export const MIN_COL_ALERT = 'You must have at least 1 column selected'
export const MIN_ROW_ALERT = 'You must have at least 1 row selected'
export const MAX_GROUP_ALERT = `You can select a maximum of ${PREFERENCE_LIMIT} groups, remove some to add more`
export const MAX_ORG_ALERT = `You can select a maximum of ${PREFERENCE_LIMIT} orgs, remove some to add more`

export const setOrgList = (data) => {
    const orgNames: { id: string; label: string }[] = []
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            orgNames.push({
                id: org.orgName,
                label: org.orgName,
            })
        })
    }
    return orgNames
}

export const setGroupList = (data) => {
    const groupNames: { id: string; label: string }[] = []
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        data[ACTION_TYPES.GROUPS_BY_ORG].data.forEach((org) => {
            org.groups.forEach((group) => {
                // users shouldn't be able to select an archived group
                if (!group?.archived) {
                    groupNames.push({
                        id: group.group_name,
                        label: group.group_name,
                    })
                }
            })
        })
    }
    return groupNames
}

export const setOrgByGroupList = (data) => {
    let orgs: any[] = []
    if (!data[ACTION_TYPES.GROUPS_BY_ORG].loading && data[ACTION_TYPES.GROUPS_BY_ORG]?.data) {
        orgs = data[ACTION_TYPES.GROUPS_BY_ORG].data.map((org) => {
            return {
                value: `${org.orgName}_org_option`,
                label: org.orgName,
                title: org.orgName,
                id: org.orgName,
                children: org.groups
                    .filter((group) => !group?.archived)
                    .map((group) => ({
                        value: group.group_name,
                        id: group.group_name,
                        title: group.group_name,
                        label: group.group_name,
                    })),
            }
        })
    }

    return orgs
}

export const ORG_PREFERENCES_HANDLERS: Map<SUMMARY_TABS, (data: any) => any[]> = new Map([
    [SUMMARY_TABS.ORG, (data) => setOrgList(data)],
    [SUMMARY_TABS.PROGRAM, (data) => setOrgList(data)],
    [SUMMARY_TABS.SPEND, (data) => setOrgList(data)],
])

export const parseArrayFromString = (tokens, defaultTokens): string[] => {
    if (!tokens) {
        return defaultTokens
    }
    if (typeof tokens === 'string') {
        return tokens.split(';')
    }
    return defaultTokens
}

const recursiveFilterColumns = (colDefs: any[], cols: string[]): any[] =>
    colDefs.filter((colDef) => {
        if (colDef.headerName && cols.includes(colDef.headerName)) {
            return false
        }

        if (colDef.children && Array.isArray(colDef.children)) {
            colDef.children = recursiveFilterColumns(colDef.children, cols)
        }
        return true
    })

export const filterColumns = (colDefs: any[], initialCols: string[]): any[] => {
    const colsToFilter = COL_LIST.filter((col) => !initialCols.includes(col))
    return recursiveFilterColumns(colDefs, colsToFilter)
}

export const getPinnedRows = (bisData, groupOHData, gapData, initialRows): any[] => {
    const pinnedRows: any[] = []
    if (initialRows.includes('bis')) {
        pinnedRows.push(bisData)
    }
    if (initialRows.includes('totalOh')) {
        pinnedRows.push(groupOHData)
    }
    if (initialRows.includes('gap')) {
        pinnedRows.push(gapData)
    }
    return pinnedRows
}
